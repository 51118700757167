import {Component, inject, Inject, OnInit, ViewChild} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogClose,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';

import {CKEditorComponent, CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {
  UntypedFormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  Alignment,
  Bold,
  ClassicEditor,
  Essentials,
  Italic,
  Link,
  List,
  ListProperties,
  Paragraph,
  Underline,
  Undo,
} from 'ckeditor5';
import {WSMButton} from '@whiteshark-media/ckeditor5-plugins';
import {NgxIntlTelInputModule} from '@whiteshark-media/ngx-intl-tel-input-app';
import {TranslateModule} from '@ngx-translate/core';
import {HelperService} from '../../helpers';

@Component({
  selector: 'app-dynamic-text',
  templateUrl: './dynamic-text.component.html',
  styleUrls: ['./dynamic-text.component.scss'],
  standalone: true,
  imports: [
    MatDialogClose,
    MatDialogContent,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    MatDialogActions,
    TranslateModule,
  ],
})
export class DynamicTextComponent implements OnInit {
  private helperService = inject(HelperService);
  @ViewChild('ckeditor', {static: false}) ckeditor: CKEditorComponent;

  // Properties
  public Editor = ClassicEditor;
  public editorInstance: ClassicEditor;
  textValueCtrl: UntypedFormControl = new UntypedFormControl();

  editorConfig = {
    toolbar: [
      'undo',
      'redo',
      '|',
      'bold',
      'italic',
      'underline',
      '|',
      'link',
      'alignment',
      'bulletedList',
      'numberedList',
      '|',
      'wsmbutton',
    ],
    plugins: [
      Essentials,
      Bold,
      Italic,
      Paragraph,
      Underline,
      Undo,
      Alignment,
      Link,
      List,
      ListProperties,
      WSMButton,
    ],
  };

  constructor(
    public dialogRef: MatDialogRef<DynamicTextComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.data?.text
      ? this.textValueCtrl.setValue(this.data.text)
      : this.textValueCtrl.setValue('');
  }

  onSave(): void {
    const tempTag = document.createElement('div');
    tempTag.innerHTML = this.textValueCtrl.value;

    const values = {
      index: this.data?.index,
      position: this.data?.position,
      text: this.textValueCtrl.value,
      plainText: tempTag.textContent || tempTag.innerText || '',
    };

    this.dialogRef.close(values);
  }
}
