import {DatePipe, TitleCasePipe, NgClass, formatDate} from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  model,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {interval, Subscription, switchMap} from 'rxjs';
import {MatSnackBarRef} from '@angular/material/snack-bar';
import {TranslateService, TranslateModule} from '@ngx-translate/core';

import {
  DialogService,
  MobileService,
  NotificationService,
  ReportingStore,
  ReportService,
  TrackingService,
  WidgetService,
} from 'src/app/core/services';
import {
  DataQueueDialogComponent,
  NewUserDialogComponent,
  ReorderPagesDialogComponent,
  ReportHistoryDialogComponent,
  SaveAsTemplateDialogComponent,
  ShareReportDialogComponent,
  ShareToUserDialogComponent,
} from 'src/app/shared/dialogs';
import {
  ClearReportCachePayload,
  CustomError,
  DataQueueStatus,
  Report,
  ReportPage,
  User,
} from '../../../../shared/models';
import {NewUpdateReportComponent} from '../new-update-report/new-update-report.component';
import {PlanType} from '../../../../shared/enums';
import {ShareLinkDialogComponent} from '../share-link-dialog/share-link-dialog.component';
import {
  openFullscreen,
  HelperService,
  downloadURL,
  canUser,
} from '../../../../shared/helpers';
import {SnackbarComponent} from '../../../../shared/components';
import {HistoryDialogData} from 'src/app/shared/dialogs/report-history-dialog/report-history-dialog.component';
import {MatTooltip} from '@angular/material/tooltip';
import {MatMenuTrigger, MatMenu, MatMenuItem} from '@angular/material/menu';
import {UpgradeButtonComponent} from '../../../../shared/components/upgrade-button/upgrade-button.component';
import {SatPopoverModule} from '@ncstate/sat-popover';
import {ReportNotificationComponent} from '../report-notification/report-notification.component';
import {WidgetToolbarComponent} from '../../../../shared/components/widgets/widget-toolbar/widget-toolbar.component';

declare global {
  interface Window {
    amplitude: any;
  }
}

const amplitude = window.amplitude;

@Component({
  selector: 'app-report-toolbar',
  templateUrl: './report-toolbar.component.html',
  styleUrls: ['./report-toolbar.component.scss'],
  providers: [DatePipe, TitleCasePipe],
  standalone: true,
  imports: [
    NgClass,
    MatTooltip,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    UpgradeButtonComponent,
    SatPopoverModule,
    ReportNotificationComponent,
    WidgetToolbarComponent,
    TranslateModule,
  ],
})
export class ReportToolbarComponent implements OnInit, OnDestroy, OnChanges {
  // Subscriptions
  public subs: Subscription = new Subscription();
  checkPdfStatusSubscription: Subscription | null;
  checkStatusSubscription: Subscription | null;

  // Inputs / Outputs
  @Input() report: Report;
  @Input() selectedPage: ReportPage;
  @Input() originalPageState: ReportPage;
  @Input() pageStatesLength: number;
  @Input() pageStateIndex: number;
  @Output() editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() widgetSave: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() widgetSelection: EventEmitter<string> = new EventEmitter<string>();
  @Output() presentationModeOn: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() reportSettingsUpdated: EventEmitter<Report> =
    new EventEmitter<Report>();
  @Output() shareReportUpdated: EventEmitter<Report> =
    new EventEmitter<Report>();
  @Output() pagesReordered: EventEmitter<Report> = new EventEmitter<Report>();
  @Output() reportRefreshed: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() pageStateChanged: EventEmitter<string> = new EventEmitter<string>();

  // signals
  dataQueueStatue = model.required<DataQueueStatus>();

  // State Variables
  isEditMode: boolean;
  wasEditing: boolean;
  isPresentationMode: boolean;
  hasChanges = signal<boolean>(false);
  loadingPdf: boolean;
  downloadCompleted = false;
  refreshingData: boolean;
  isExternal: boolean;
  isReportLoading = false;
  largeMobile: boolean;
  isMobile: boolean;

  // Properties
  warningMessages: {type: string; message: string}[] = [];
  infoMessages: {type: string; message: string}[] = [];
  userPlatform: string;
  undoTooltip: string;
  redoTooltip: string;
  userPlan = 'org';
  refreshUrl: string;
  snackbarTemplate: string;
  refreshSnackbar: MatSnackBarRef<SnackbarComponent>;
  userPlanType: PlanType = this.helperService.getUserPlan();
  canShareWith = canUser('shareWith', this.userPlanType);
  public userLocale = Intl.DateTimeFormat().resolvedOptions().locale;

  // Host Listeners
  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  screenChange(): void {
    this.isPresentationMode = !this.isPresentationMode;
    this.presentationModeOn.emit(this.isPresentationMode);
    if (!this.isPresentationMode && this.wasEditing) {
      this.widgetService.cascadeEditMode(true);
      this.isEditMode = true;
      this.wasEditing = false;
    }
  }

  constructor(
    private widgetService: WidgetService,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private helperService: HelperService,
    private translate: TranslateService,
    private router: Router,
    private dialog: MatDialog,
    private titleCasePipe: TitleCasePipe,
    private reportStorage: ReportingStore,
    private mobileService: MobileService,
    private dialogService: DialogService,
    private trackingService: TrackingService
  ) {}

  ngOnInit(): void {
    this.userPlatform = this.helperService.getUserPlatform();
    this.getTranslations();
    if (this.report.processStatus !== 'DONE') {
      this.downloadCompleted = false;
      this.checkReportStatus();
    }

    // Has Changes Subscription
    this.reportService.hasChanges$.subscribe(
      (res: {hasChanges: boolean; skipPageState?: boolean}): void => {
        this.hasChanges.set(res.hasChanges);
      }
    );

    // Report general loading subscription
    this.subs.add(
      this.reportService.isReportLoading$.subscribe((res: boolean): void => {
        this.isReportLoading = res;
      })
    );

    // Mobile subscription
    this.subs.add(
      this.mobileService.getMobileBehavior().subscribe((res: boolean): void => {
        this.isMobile = res;
      })
    );

    if (this.isMobile) {
      this.subs.add(
        this.mobileService.mobileWindow$.subscribe((res: string): void => {
          this.largeMobile = res === 'large';
        })
      );
    }

    this.isExternal = JSON.parse(
      localStorage.getItem('currentUser') as string
    ).roles.includes('ROLE_EXTERNAL');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.originalPageState) {
      this.originalPageState = changes.originalPageState.currentValue;
    }
  }

  getTranslations(): void {
    this.translate.get('reporting.reportToolbar.undo_label').subscribe({
      next: (res) =>
        (this.undoTooltip =
          res + (this.userPlatform == 'MacOS' ? ' (⌘ + Z)' : ' (Ctrl + Z)')),
    });

    this.translate.get('reporting.reportToolbar.redo_label').subscribe({
      next: (res) =>
        (this.redoTooltip =
          res +
          (this.userPlatform == 'MacOS'
            ? ' (Shift + ⌘ + Z)'
            : ' (Shift + Ctrl + Z)')),
    });
  }

  toggleEditMode(): void {
    this.isEditMode = !this.isEditMode;
    this.editModeChange.emit(this.isEditMode);
  }

  onWidgetSelect(widgetType: string): void {
    this.widgetSelection.emit(widgetType);
  }

  saveMode(): void {
    this.widgetSave.emit(true);
  }

  changePageState(type: string): void {
    if (this.pageStatesLength < 1) {
      return;
    }
    this.pageStateChanged.emit(type);
  }

  openReorderPages(): void {
    if (this.hasChanges()) {
      this.reportService.warnOfUnsavedChanges(
        this.openReorderPages.bind(this),
        undefined,
        this.reportService.returnToOriginalPageState.bind(
          this,
          this.report.pages,
          this.selectedPage,
          this.originalPageState
        ),
        'report toolbar openReorderPage'
      );
    } else {
      const dialogRef = this.dialog.open(ReorderPagesDialogComponent, {
        width: '30vw',
        height: '60vh',
        data: {
          pages: this.report.pages,
          reportId: this.report.id,
          currentPageId: this.selectedPage.id,
        },
      });

      const dialogSub: Subscription = this.router.events.subscribe((): void => {
        dialogRef.close();
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.report = res;
          this.pagesReordered.emit(this.report);
        }
        dialogSub.unsubscribe();
      });
    }
  }

  public openFullScreen(): void {
    if (this.hasChanges()) {
      this.reportService.warnOfUnsavedChanges(
        this.openFullScreen.bind(this),
        undefined,
        this.reportService.returnToOriginalPageState.bind(
          this,
          this.report.pages,
          this.selectedPage,
          this.originalPageState
        ),
        'report toolbar openFullScreen'
      );
    } else {
      this.widgetService.cascadeEditMode(false);
      this.wasEditing = this.isEditMode;
      this.isEditMode = false;
      openFullscreen('report-grid');
    }
  }

  public onEditReportModal(): void {
    if (this.hasChanges()) {
      this.reportService.warnOfUnsavedChanges(
        this.onEditReportModal.bind(this),
        undefined,
        this.reportService.returnToOriginalPageState.bind(
          this,
          this.report.pages,
          this.selectedPage,
          this.originalPageState
        ),
        'report toolbar onEditReportModal'
      );
    } else {
      const reportSandbox: any = {};
      Object.assign(reportSandbox, this.report);

      // Remove unnecessary fields.
      delete reportSandbox.pages;

      const dialogRef: MatDialogRef<NewUpdateReportComponent> =
        this.dialog.open(NewUpdateReportComponent, {
          data: {
            report: reportSandbox,
          },
          width: '75vw',
          hasBackdrop: true,
        });

      const dialogSub: Subscription = this.router.events.subscribe((): void => {
        dialogRef.close();
      });

      dialogRef.afterClosed().subscribe((res: {report: Report}): void => {
        if (res) {
          this.reportSettingsUpdated.emit(res.report);
          if (reportSandbox.theme.name !== res.report.theme.name) {
            this.reportStorage.clearState();
          }
        }
        dialogSub.unsubscribe();
      });
    }
  }

  onSaveTemplate(): void {
    if (this.hasChanges()) {
      this.reportService.warnOfUnsavedChanges(
        this.onSaveTemplate.bind(this),
        undefined,
        this.reportService.returnToOriginalPageState.bind(
          this,
          this.report.pages,
          this.selectedPage,
          this.originalPageState
        ),
        'reportoolbar onSaveTemplate'
      );
    } else {
      const dialogRef: MatDialogRef<SaveAsTemplateDialogComponent> =
        this.dialog.open(SaveAsTemplateDialogComponent, {
          data: {
            reportId: this.report.id,
            type: 'report',
            orientation: this.report.orientation,
          },
          width: '43vw',
          height: '66vh',
          autoFocus: false,
          closeOnNavigation: true,
        });

      const dialogSub: Subscription = this.router.events.subscribe((): void => {
        dialogRef.close();
      });

      dialogRef.afterClosed().subscribe((): void => {
        dialogSub.unsubscribe();
      });
    }
  }

  openReportHistory(): void {
    this.dialogService.openDialog(ReportHistoryDialogComponent, {
      data: {
        reportId: this.report.id,
        files: this.report.fileHistory,
        isMobile: this.isMobile,
        overrideWidth: true,
      } as HistoryDialogData,
      autoFocus: false,
      disableClose: false,
      width: '40vw',
    });
  }

  openShareReportDialog(type: string, sfData?): void {
    if (this.hasChanges()) {
      this.reportService.warnOfUnsavedChanges(
        this.openShareReportDialog.bind(this, 'email'),
        undefined,
        this.reportService.returnToOriginalPageState.bind(
          this,
          this.report.pages,
          this.selectedPage,
          this.originalPageState
        ),
        'reportToolbar openShareReportDialog'
      );
    } else {
      const onCloseActions = (res?): void => {
        if (res) {
          this.trackingService.trackEvent('Share Report by Email');
          this.shareReportUpdated.emit(res);
        }
      };
      this.dialogService.openDialog(
        ShareReportDialogComponent,
        {
          data: {
            report: this.report,
            type,
            sfData,
            isMobile: this.isMobile,
            overrideWidth: true,
          },
          width: '40vw',
          autoFocus: false,
        },
        onCloseActions.bind(this)
      );
    }
  }

  openAddUsersDialog(user?: User): void {
    if (this.hasChanges()) {
      this.reportService.warnOfUnsavedChanges(
        this.openAddUsersDialog.bind(this),
        undefined,
        this.reportService.returnToOriginalPageState.bind(
          this,
          this.report.pages,
          this.selectedPage,
          this.originalPageState
        ),
        'report toolbar openAddUSerDialog'
      );
    } else {
      const onCloseActions = (res): void => {
        if (res) {
          if (res.openCreateUser) {
            this.openCreateUserDialog();
          }
        }
      };
      this.dialogService.openDialog(
        ShareToUserDialogComponent,
        {
          data: {
            reportId: this.report.id,
            user: user ? user : null,
            isMobile: this.isMobile,
            overrideWidth: true,
          },
          width: '32vw',
          height: '60vh',
          panelClass: ['full-spinner'],
        },
        onCloseActions.bind(this)
      );
    }
  }

  openCreateUserDialog(): void {
    const onCloseActions = (res?): void => {
      switch (res?.type) {
        case 'userCreated':
          this.openAddUsersDialog(res.user);
          break;
        case 'back':
          this.openAddUsersDialog();
          break;
      }
    };
    this.dialogService.openDialog(
      NewUserDialogComponent,
      {
        data: {
          externalUser: true,
          isMobile: this.isMobile,
        },
        width: '45vw',
      },
      onCloseActions.bind(this)
    );
  }

  getReportPdf(): void {
    if (this.hasChanges()) {
      this.reportService.warnOfUnsavedChanges(
        this.getReportPdf.bind(this),
        undefined,
        this.reportService.returnToOriginalPageState.bind(
          this,
          this.report.pages,
          this.selectedPage,
          this.originalPageState
        ),
        'report toolbar getReportPDF'
      );
    } else {
      this.loadingPdf = true;
      let pdfId;

      this.reportService.downloadReport(this.report.id).subscribe({
        next: (res): void => {
          pdfId = res.pdfId;
        },
        error: (err: CustomError): void => {
          this.loadingPdf = false;
          this.translate
            .get('notifications.error_download_report')
            .subscribe((res: string): void => {
              this.notificationService.error(err?.message ?? res, 5000);
            });
        },
        complete: (): void => {
          this.checkPdfStatus(pdfId);
        },
      });
    }
  }

  checkPdfStatus(pdfId: string): void {
    if (!this.checkPdfStatusSubscription) {
      let pdfBlob: Blob;
      this.checkPdfStatusSubscription = interval(7000)
        .pipe(
          switchMap(() =>
            this.reportService.checkPdfStatus(this.report.id, pdfId)
          )
        )
        .subscribe({
          next: (res: Blob): void => {
            if (res?.type === 'application/pdf') {
              pdfBlob = res;
              this.downloadPdf(pdfBlob);
              this.checkPdfStatusSubscription?.unsubscribe();
              this.checkPdfStatusSubscription = null;
              this.loadingPdf = false;
            }
          },
          error: (err: CustomError): void => {
            this.loadingPdf = false;
            this.notificationService.error(err?.message ?? '', 5000);
          },
        });
    }
  }

  public downloadPdf(pdfBlob: Blob): void {
    this.trackingService.trackEvent('Download Report PDF');
    if (pdfBlob) {
      const pdfName: string = this.titleCasePipe
        .transform(this.report.name)
        .concat(' ')
        .concat(formatDate(new Date(), 'yyyyMMdd', this.userLocale) as string)
        .replace(/ /g, '-')
        .concat('.pdf');

      const url: string = window.URL.createObjectURL(pdfBlob);
      this.downloadURL(url, pdfName);
      setTimeout(() => {
        return window.URL.revokeObjectURL(url);
      }, 1000);
    }
  }

  downloadURL(data: string, fileName: string): void {
    downloadURL(data, fileName);
  }

  public refreshData(): void {
    this.refreshingData = true;
    this.report.processStatus = 'QUEUE';
    this.report.processPending = true;
    this.reportService.refreshReportData(this.report.id).subscribe({
      next: (): void => {
        this.checkReportStatus();
      },
      error: (err: CustomError): void => {
        this.notificationService.error(err?.message as string, 5000);
      },
    });
  }

  checkReportStatus(): void {
    if (!this.checkStatusSubscription) {
      this.checkStatusSubscription = interval(15000)
        .pipe(
          switchMap(() => this.reportService.getReportStatus(this.report.id))
        )
        .subscribe({
          next: (res): void => {
            const reportStatus = res;
            this.report.processStatus = res;
            if (reportStatus === 'DONE' || reportStatus === 'ERROR') {
              if (reportStatus === 'DONE') {
                this.downloadCompleted = true;
                this.report.processPending = false;
                this.report.processError = false;
                this.refreshingData = false;
                this.onRefreshDataAfterSync();
              }
              this.checkStatusSubscription?.unsubscribe();
              this.checkStatusSubscription = null;
            }
          },
          error: (err): void => {
            this.refreshingData = false;
            this.notificationService.error(err.message, 5000);
          },
        });
      this.subs.add(this.checkStatusSubscription);
    }
  }

  openDataQueue(): void {
    this.dialogService.openDialog(
      DataQueueDialogComponent,
      {
        data: {
          report: this.report,
          isMobile: this.isMobile,
          largeMobile: this.largeMobile,
          overrideWidth: false,
        },
        width: this.isMobile ? '90vw' : '60vw',
        maxWidth: this.isMobile ? '90vw' : '',
        minHeight: this.isMobile ? '50vh' : '',
        panelClass: ['full-spinner'],
      },
      (res) => {
        if (res) {
          this.dataQueueStatue.set(res as DataQueueStatus);
        }
      }
    );
  }

  onRefreshDataAfterSync(): void {
    this.snackbarTemplate = `<div>${this.translate.instant(
      'reporting.notifications.data_downloaded_one'
    )} <button class="wsm-btn wsm-btn-link" id="refreshReport">${this.translate.instant(
      'reporting.notifications.data_downloaded_two'
    )}</button> ${this.translate.instant(
      'reporting.notifications.data_downloaded_three'
    )}</div>`;
    this.refreshSnackbar = this.notificationService.info(
      this.snackbarTemplate,
      undefined,
      undefined,
      undefined,
      true
    );

    document
      .getElementById('refreshReport')
      ?.addEventListener('click', (): void => {
        this.reportRefreshed.emit(true);
        this.refreshSnackbar.dismiss();
      });
  }

  clearReportCache(): void {
    const payload: ClearReportCachePayload = {
      reportIds: [this.report.id],
    };
    this.reportService.clearReportCache(payload).subscribe({
      next: (): void => {},
      error: (): void => {
        this.translate
          .get('notifications.clear_cache_error')
          .subscribe((res: string): void => {
            this.notificationService.error(res, 5000);
          });
      },
      complete: (): void => {
        const snackBarRef = this.notificationService.success(
          this.translate.instant('reporting.reportToolbar.cache_cleaned'),
          undefined,
          this.translate.instant('reporting.reportToolbar.refresh_label')
        );
        snackBarRef.onAction().subscribe((): void => {
          this.reportRefreshed.emit(true);
        });

        this.router.events.subscribe((): void => {
          snackBarRef.dismiss();
        });
      },
    });
  }

  openShareLinkDialog(): void {
    this.trackingService.trackEvent('Copy Report Shareable Link');
    this.dialogService.openDialog(ShareLinkDialogComponent, {
      data: {
        reportId: this.report.id,
        isMobile: this.isMobile,
      },
      width: '45vw',
      maxWidth: '45vw',
      height: '30vh',
      minWidth: '320px',
      minHeight: '280px',
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.refreshSnackbar?.dismiss();
  }
}
