<div
  [ngClass]="{'dark-content': widget.darkMode === 'on'}"
  class="ad-container"
>
  @if (widget?.query) {
    <div
      class="info-box"
      [ngStyle]="{
        'background': theme.pageColor,
        'border-color': theme.borderColor,
      }"
    >
      <span
        id="adMetricLabel"
        class="metric-label"
        [ngStyle]="{color: theme?.titleColor}"
      >
        {{ widget.metrics![0]?.name }}
      </span>
      @if ((widget?.metrics)![0]) {
        <span
          id="adMetricValue"
          class="metric-value"
          [ngStyle]="{color: theme?.bodyColor}"
        >
          @if (ad?.values?.newValue) {
            {{ ad?.values?.newValue }}
          }
        </span>
      }
      @if (
        (widget?.metrics)![0] && ad?.values?.hasOwnProperty("deltaPercent")
      ) {
        <app-widget-popover
          [pipeType]="widget.metrics![0]?.dataType!"
          [popoverData]="ad?.values"
          [theme]="theme"
          [isFromGallery]="true"
        >
        </app-widget-popover>
      }
    </div>
  }

  @if (ad?.adPreviewError) {
    <div class="wsm__ad py-3 d-flex align-items-center">
      <span class="mdi mdi-18px mdi-alert mr-3 alert-icon"></span>
      <p class="preview-error">
        {{ "reporting.widgets.adGallery.preview_error" | translate }}
        <br />
        @if (!isExternalUser) {
          <a [href]="helpUrl" target="_blank">
            {{
              "reporting.widgets.adGallery.preview_error_support" | translate
            }}
          </a>
        } @else {
          <span>{{ "common.contact_administrator" | translate }}</span>
        }
      </p>
    </div>
  }

  @if (!ad?.adPreviewError && ad?.preview) {
    <div [innerHTML]="ad?.preview | safeHtml"></div>
  }

  @if (!ad?.adPreviewError && !ad?.preview) {
    <div [innerHTML]="ad | safeHtml"></div>
  }
</div>
