<section class="w-100 h-100 kpi-card">
  <!-- KPI Label -->
  <span
    [ngStyle]="{'color': theme.titleColor, 'font-size': theme.titleFontSize}"
    class="kpi-label w-10 justify-content-center"
    [ngClass]="{
      'bold-text': this.theme?.labelBold === true,
      'italic-text': this.theme?.labelItalic === true,
      'text-start': this.theme?.labelAlign === 'left',
      'text-center': this.theme?.labelAlign === 'center',
      'text-end': this.theme?.labelAlign === 'right',
    }"
    title="{{ widget.kpiLabel ? widget.kpiLabel : widget.kpiData?.name }}"
  >
    <span
      [ngClass]="{
        'flex-row-reverse':
          widget.widgetIcon && widget.widgetIcon.position === 'right',
        'flex-column justify-content-center':
          widget.widgetIcon && widget.widgetIcon.position === 'center_up',
        'flex-column-reverse justify-content-center':
          widget.widgetIcon && widget.widgetIcon.position === 'center_down',
      }"
      class="d-flex gap-1 align-items-center"
      id="scorecardKpiTitle"
    >
      @if (widget?.widgetIcon) {
        <i [class]="widget.widgetIcon?.faClass"></i>
      }
      <span>
        {{
          widget?.isEditStyle
            ? widget?.kpiLabel
              ? widget.kpiLabel
              : widget?.kpiData?.name
            : widget?.dynamicKpiLabel || widget?.kpiLabel
        }}
      </span>
    </span>
  </span>

  <div class="d-flex flex-column">
    <!-- KPI Value -->
    <span
      [ngStyle]="{'color': theme.bodyColor}"
      class="kpi-value"
      id="scorecardKpiValue"
    >
      @switch (widget.kpiData?.dataType) {
        @case ("currency") {
          {{ widget.kpiData?.currentValueLabel }}
        }
        @case ("float") {
          {{ widget.kpiData?.currentValueLabel }}
        }
        @case ("integer") {
          {{ widget.kpiData?.currentValueLabel }}
        }
        @case ("decimal") {
          {{ widget.kpiData?.currentValueLabel }}
        }
        @case ("percentage") {
          {{ widget.kpiData?.currentValue | percent: "1.2" }}
        }
        @case ("time") {
          {{ widget.kpiData?.currentValue! | transformDate }}
        }
        @default {
          {{ widget.kpiData?.currentValue }}
        }
      }
    </span>
    <!-- KPI Compare -->
    <div
      class="kpi-compare {{
        theme?.schemaIndicator
          ? 'indicator-' + theme?.schemaIndicator
          : 'indicator-opt-1'
      }} "
    >
      @if (widget.kpiData?.previousValue) {
        <span class="kpi-prev-value">
          @switch (widget.kpiData?.dataType) {
            @case ("currency") {
              {{ widget.kpiData?.previousValueLabel }}
            }
            @case ("float") {
              {{ widget.kpiData?.previousValueLabel }}
            }
            @case ("integer") {
              {{ widget.kpiData?.previousValueLabel }}
            }
            @case ("decimal") {
              {{ widget.kpiData?.previousValueLabel }}
            }
            @case ("percentage") {
              {{ widget.kpiData?.previousValue | percent: "1.2" }}
            }
            @case ("time") {
              {{ widget.kpiData?.previousValue! | transformDate }}
            }
            @default {
              {{ widget.kpiData?.previousValue }}
            }
          }
        </span>
      }

      @if (widget.kpiData?.previousValue) {
        <span
          [ngClass]="
            widget.kpiData?.isPositive ? 'divider-success' : 'divider-error'
          "
          class="kpi-divider"
          >|</span
        >
      }

      @if (widget.kpiData?.previousValue) {
        <span
          [ngClass]="widget.kpiData?.isPositive ? 'positive' : 'negative'"
          class="kpi-delta"
        >
          @if (widget.kpiData?.deltaPercent !== null) {
            <span class="d-flex justify-content-center align-items-center">
              {{
                widget.kpiData?.deltaPercent! !== 0
                  ? (widget.kpiData?.deltaPercent! | percent: "1.2")
                  : "—"
              }}
              <span
                [hidden]="widget.kpiData?.deltaPercent! === 0"
                [ngClass]="
                  widget.kpiData?.deltaPercent! > 0
                    ? 'fa-arrow-up'
                    : 'fa-arrow-down'
                "
                class="fa-regular ms-1"
              >
              </span>
            </span>
          }
        </span>
      }
    </div>
  </div>
</section>
