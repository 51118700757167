<header class="mb-4">
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h3 m-0">
      {{ "shared.dialogs.dynamic_text.title" | translate }}
    </h1>

    <button
      [mat-dialog-close]="false"
      class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
    >
      <i class="fa-regular fa-xmark"></i>
    </button>
  </div>
</header>

<mat-dialog-content>
  <div class="editor-container">
    <ckeditor
      #ckeditor
      [config]="editorConfig"
      [editor]="Editor"
      [formControl]="textValueCtrl"
    >
    </ckeditor>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <button
    (click)="onSave()"
    [disabled]="textValueCtrl?.value?.length <= 0"
    class="wsm-btn wsm-btn-primary my-2"
  >
    {{ "shared.dialogs.dynamic_text.save_button" | translate }}
  </button>
</mat-dialog-actions>
