<header class="d-flex justify-content-between">
  <div class="d-flex flex-column">
    <h4>
      {{ "reporting.create_report.wizard_modal.title" | translate }}
    </h4>
    <p>{{ "reporting.create_report.wizard_modal.description" | translate }}</p>
  </div>
  <button
    (click)="closeDialog()"
    data-cy-btnclose="close-dialog"
    class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
  >
    <i class="fa-regular fa-xmark"></i>
  </button>
</header>

<mat-dialog-content>
  <mat-horizontal-stepper #reportWizard labelPosition="bottom" linear>
    <ng-template matStepperIcon="edit">
      <i class="fa-regular fa-check"></i>
    </ng-template>

    <!--FIRST STEP-->
    <mat-step #startAsStep [completed]="firstStepCompleted()">
      <app-start-as
        (startAsChange)="onChangeStart($event)"
        [(startAs)]="startAs"
      ></app-start-as>
    </mat-step>
    <!--SECOND STEP-->
    <mat-step #templateOrCustomStep [completed]="secondStepCompleted()">
      @if (firstStepCompleted() && reportWizard.selectedIndex === 1) {
        @switch (startAs()) {
          @case ("template") {
            <ng-template [ngTemplateOutlet]="templateStepTwo"></ng-template>
          }
          @case ("custom") {
            <ng-template
              [ngTemplateOutlet]="customStepTwo"
              data-cy-custom-report="app-to-custom-report"
            ></ng-template>
          }
        }
        <ng-template #templateStepTwo>
          <app-to-select-template
            [(templateSelected)]="templateSelected"
            (changeTemplateSelected)="onTemplateSelected($event)"
          />
        </ng-template>
        <ng-template #customStepTwo>
          <app-to-custom-report
            (changeToCustomReportProperties)="
              onChangeToCustomReportProperties($event)
            "
            [(toCustomReportProperties)]="toCustomReportProperties"
          />
        </ng-template>
      }
    </mat-step>

    <!--THIRD STEP-->
    @if (!this.data.clientId) {
      <mat-step #generalOrClientsStep [completed]="thirdStepCompleted()">
        @if (secondStepCompleted() && reportWizard.selectedIndex === 2) {
          @switch (startAs()) {
            @case ("template") {
              <ng-template [ngTemplateOutlet]="templateStepThree"></ng-template>
            }
            @case ("custom") {
              <ng-template [ngTemplateOutlet]="customStepThree"></ng-template>
            }
          }
          <ng-template #templateStepThree>
            <small class="d-flex wsm-text-grayed mb-3">
              {{ "common.fields_required" | translate }}
            </small>
            <app-create-report-from
              [dataType]="dataTypeSelected()"
              [conditionFromWizard]="conditionsSelected()!"
              [isFromWizard]="true"
              (setNameAndCondition)="getNameAndConditions($event)"
            ></app-create-report-from>
          </ng-template>
          <ng-template #customStepThree>
            <small class="d-flex wsm-text-grayed mb-3">
              {{ "common.fields_required" | translate }}
            </small>
            <label
              >{{
                "reporting.create_report.form.client" | translate
              }}&nbsp;<span class="required-mark fw-bolder">*</span></label
            >
            <app-company-selection
              [conditionsFromWizard]="conditionsSelected()"
              [companyDataType]="dataTypeSelected()"
              [showTitle]="false"
              (sendConditions)="getCustomReportConditions($event)"
            ></app-company-selection>
          </ng-template>
        }
      </mat-step>
    }
  </mat-horizontal-stepper>
</mat-dialog-content>

<mat-dialog-actions
  [ngClass]="{
    'justify-content-end': reportWizard.selectedIndex === 0,
    'justify-content-between': reportWizard.selectedIndex !== 0,
  }"
>
  @if (reportWizard.selectedIndex !== 0) {
    <button
      class="wsm-btn wsm-btn-outline"
      (click)="moveToPreviousStep(reportWizard)"
      cy-data-btn="previous-step"
    >
      {{ "reporting.create_report.wizard_modal.back_button" | translate }}
    </button>
  }

  @if (!data?.clientId) {
    @if (
      (reportWizard.selectedIndex !== 2 && !thirdStepCompleted()) ||
      reportWizard.selectedIndex !== 2
    ) {
      <button
        class="wsm-btn wsm-btn-outline"
        cy-data-btn="next-step"
        [disabled]="!secondStepCompleted() && reportWizard.selectedIndex !== 0"
        (click)="moveToNextStep(reportWizard)"
      >
        {{ "reporting.create_report.wizard_modal.next_button" | translate }}
      </button>
    }
    @if (reportWizard.selectedIndex === 2) {
      <button
        class="wsm-btn wsm-btn-primary"
        cy-data-btn="submit-report"
        [disabled]="!thirdStepCompleted() || isSavingData()"
        (click)="onSubmitReport()"
      >
        @if (!isSavingData()) {
          <span>
            {{ "reporting.create_report.wizard_modal.save_report" | translate }}
          </span>
        }
        @if (isSavingData()) {
          <div
            class="spinner-border spinner-border-sm text-light"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        }
      </button>
    }
  } @else {
    @if (reportWizard.selectedIndex !== 1) {
      <button
        class="wsm-btn wsm-btn-outline"
        (click)="moveToNextStep(reportWizard)"
        cy-data-btn="next-step"
      >
        {{ "reporting.create_report.wizard_modal.next_button" | translate }}
      </button>
    }
    @if (reportWizard.selectedIndex === 1) {
      <button
        class="wsm-btn wsm-btn-primary"
        [disabled]="!secondStepCompleted() || isSavingData()"
        (click)="onSubmitReport()"
        data-cy-btn="next-step"
      >
        @if (!isSavingData()) {
          <span>
            {{ "reporting.create_report.wizard_modal.save_report" | translate }}
          </span>
        }
        @if (isSavingData()) {
          <div
            class="spinner-border spinner-border-sm text-light"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        }
      </button>
    }
  }
</mat-dialog-actions>
