<div
  [ngClass]="{'header': useAs === 'header', 'body': useAs === 'body'}"
  class="title-container"
>
  @if (isEditing) {
    <input
      [cdkTrapFocusAutoCapture]="isEditing"
      [cdkTrapFocus]="isEditing"
      type="text"
      [ngClass]="{'header': useAs === 'header'}"
      class="wsm-input editable-input h3 validate"
      [class]="inputSize"
      [formControl]="title"
    />
  }
  @if (!isEditing) {
    <ng-container *ngTemplateOutlet="templateRef ? templateRef : normal">
    </ng-container>
    <ng-template #normal>
      @if (useAs === "header") {
        <h1 class="h2 mb-0">{{ title.value }}</h1>
      }
    </ng-template>
  }
  @if (editMode) {
    @if (!isEditing) {
      <button
        [disabled]="title.invalid && isEditing"
        [ngClass]="{
          'wsm-btn-small-icon': useAs === 'header',
          'wsm-btn-round-icon-xs': useAs === 'body',
        }"
        class="wsm-btn wsm-btn-outline-icon ms-2"
        (click)="editTitle($event)"
      >
        <i class="fa-regular fa-pen"></i>
      </button>
    }
    @if (isEditing && valueChanged) {
      <button
        [disabled]="title.invalid && isEditing"
        [ngClass]="{
          'wsm-btn-small-icon': useAs === 'header',
          'wsm-btn-round-icon-xs': useAs === 'body',
        }"
        class="wsm-btn wsm-btn-outline-icon ms-2"
        (click)="editTitle($event)"
      >
        <i class="fa-regular fa-floppy-disk"></i>
      </button>
    }
    @if (isEditing) {
      <button
        [ngClass]="{
          'wsm-btn-small-icon': useAs === 'header',
          'wsm-btn-round-icon-xs': useAs === 'body',
        }"
        class="wsm-btn wsm-btn-outline-icon ms-2"
        (click)="cancelEdit()"
      >
        <i class="fa-regular fa-xmark"></i>
      </button>
    }
  }
</div>
