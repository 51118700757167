import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {ReportTheme, Widget} from '../../../../models';
import {HelperService} from '../../../../helpers';
import {NgStyle, NgClass, PercentPipe} from '@angular/common';
import {TransformDatePipe} from '../../../../pipes/transform-date.pipe';

@Component({
  selector: 'app-scorecard',
  templateUrl: './scorecard.component.html',
  styleUrls: ['./scorecard.component.scss'],
  standalone: true,
  imports: [NgStyle, NgClass, PercentPipe, TransformDatePipe],
})
export class ScorecardComponent implements OnInit, OnChanges {
  // Inputs / Output
  @Input() widget: Widget;
  @Input() theme: ReportTheme = {};

  // States
  private initialized: boolean;

  constructor(private helperService: HelperService) {}

  ngOnInit(): void {
    this.assignMetricLabels();
    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialized) {
      return;
    }

    if (changes.widget) {
      this.widget = changes.widget.currentValue;
      this.assignMetricLabels();
    }
  }

  private assignMetricLabels(): void {
    if (
      this.widget.kpiData?.dataType === 'currency' ||
      this.widget.kpiData?.dataType === 'integer' ||
      this.widget.kpiData?.dataType === 'decimal' ||
      this.widget.kpiData?.dataType === 'float'
    ) {
      this.widget.kpiData.currentValueLabel =
        this.helperService.updateMetricData(
          this.widget.kpiData.dataType,
          this.widget.kpiData?.currentValue as number,
          this.widget.kpiData.currency,
          this.widget.metrics ? this.widget.metrics[0]?.shortenNumbers : false
        );

      this.widget.kpiData.previousValueLabel =
        this.helperService.updateMetricData(
          this.widget.kpiData?.dataType,
          this.widget.kpiData?.previousValue as number,
          this.widget.kpiData?.currency,
          this.widget.metrics ? this.widget.metrics[0]?.shortenNumbers : false
        );
    }
  }
}
