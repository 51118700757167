import {
  Component,
  EventEmitter,
  HostListener,
  input,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {
  debounceTime,
  distinctUntilChanged,
  pairwise,
  ReplaySubject,
  Subject,
  Subscription,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import {
  CdkDragDrop,
  moveItemInArray,
  CdkDropListGroup,
  CdkDropList,
  CdkDrag,
} from '@angular/cdk/drag-drop';
import {
  MatMenuTrigger,
  MatMenu,
  MatMenuContent,
  MatMenuItem,
} from '@angular/material/menu';

import {TranslateService, TranslateModule} from '@ngx-translate/core';

import {ConditionalMetricComponent} from '../../conditional-metric/conditional-metric.component';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import {CKEditorComponent, CKEditorModule} from '@ckeditor/ckeditor5-angular';

import {
  Alignment,
  Bold,
  ButtonView,
  ClassicEditor,
  Essentials,
  Italic,
  Link,
  List,
  ListProperties,
  Paragraph,
  Underline,
  Undo,
} from 'ckeditor5';
import {
  WSMButton,
  WsmDateRangeButton,
} from '@whiteshark-media/ckeditor5-plugins';
import {ChartTypeComponent} from '../widget-settings/chart-type/chart-type.component';
import {MatTooltip} from '@angular/material/tooltip';
import {NgxIntlTelInputModule} from '@whiteshark-media/ngx-intl-tel-input-app';
import {MatOption} from '@angular/material/core';
import {ConnectionLogosComponent} from '../../connection-logos/connection-logos.component';
import {
  MatChipOption,
  MatChipRemove,
  MatChipListbox,
} from '@angular/material/chips';
import {NgClass, AsyncPipe, DatePipe, NgTemplateOutlet} from '@angular/common';
import {MatIcon} from '@angular/material/icon';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {
  MatButtonToggleGroup,
  MatButtonToggle,
} from '@angular/material/button-toggle';
import {MatSelect} from '@angular/material/select';
import {FilterOperatorComponent} from '../../filter-operator/filter-operator.component';
import {FilterSelectionComponent} from '../../filter-selection/filter-selection.component';
import {DatePickerComponent} from '../../date-picker/date-picker/date-picker.component';
import {TruncatePipe} from '../../../pipes/truncate-string.pipe';
import {ShowSortByPipe} from '../../../pipes/show-sort-by.pipe';
import {JoinStringArrayPipe} from '../../../pipes/join-string-array.pipe';
import {ConfirmationDialogComponent} from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import {
  Widget,
  WidgetConfiguration,
  WidgetWithDimension,
  WidgetWithGoalAmount,
  WidgetWithMetrics,
} from 'src/app/shared/models/widget/widget.model';
import {
  ReportDateRange,
  ReportFilter,
  ReportPage,
} from 'src/app/shared/models/report/report.model';
import {Dataset, Scope, ScopeField} from 'src/app/shared/models/dataset.model';
import {Connection} from 'src/app/shared/models/connections.model';
import {ScopeService} from 'src/app/shared/helpers/scope.service';
import {ValidateAdDatasetFields} from 'src/app/shared/helpers/custom.validator';
import {NotificationService} from 'src/app/core/services/notification.service';
import {WidgetService} from 'src/app/core/services/widget.service';
import {FilterLogicComponent} from 'src/app/shared/dialogs/filter-logic/filter-logic.component';
import {DynamicMetricsComponent} from 'src/app/shared/dialogs/dynamic-metrics/dynamic-metrics.component';
import {DynamicDateRangeTextComponent} from 'src/app/shared/dialogs/dynamic-date-range-text/dynamic-date-range-text.component';
import {HelperService} from 'src/app/shared/helpers';

@Component({
  selector: 'app-widget-data',
  templateUrl: './widget-data.component.html',
  styleUrls: ['./widget-data.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ChartTypeComponent,
    MatTooltip,
    MatAutocompleteTrigger,
    NgxIntlTelInputModule,
    MatAutocomplete,
    MatOption,
    ConnectionLogosComponent,
    CdkDropListGroup,
    CdkDropList,
    MatChipOption,
    MatMenuTrigger,
    NgClass,
    CdkDrag,
    MatIcon,
    MatChipRemove,
    MatMenu,
    MatMenuContent,
    MatSlideToggle,
    MatButtonToggleGroup,
    MatButtonToggle,
    MatSelect,
    MatChipListbox,
    MatMenuItem,
    FilterOperatorComponent,
    FilterSelectionComponent,
    DatePickerComponent,
    CKEditorModule,
    ConditionalMetricComponent,
    AsyncPipe,
    DatePipe,
    TranslateModule,
    TruncatePipe,
    ShowSortByPipe,
    JoinStringArrayPipe,
    NgTemplateOutlet,
  ],
})
export class WidgetDataComponent implements OnInit, OnDestroy, OnChanges {
  public widgetMetricInfoTranslate = signal<Record<WidgetWithMetrics, string>>({
    funnel: 'reporting.widget_editor.data_form.metric_info.funnel',
    pie2d: 'reporting.widget_editor.data_form.metric_info.pie',
    doughnut2d: 'reporting.widget_editor.data_form.metric_info.doughnut',
    mscombidy2d: 'reporting.widget_editor.data_form.metric_info.mixed_chart',
    multiaxisline: 'reporting.widget_editor.data_form.metric_info.line_chart',
    msarea: 'reporting.widget_editor.data_form.metric_info.area_chart',
    stackedcolumn2d:
      'reporting.widget_editor.data_form.metric_info.stacked_column',
    stackedbar2d: 'reporting.widget_editor.data_form.metric_info.stacked_bar',
    biggestchanges:
      'reporting.widget_editor.data_form.metric_info.biggest_changes',
    gauge: 'reporting.widget_editor.data_form.metric_info.gauge_chart',
    'current-goal':
      'reporting.widget_editor.data_form.metric_info.current_goal',
    timeline: 'reporting.widget_editor.data_form.metric_info.timeline',
    gallery: 'reporting.widget_editor.data_form.metric_info.ad_gallery',
    ranking: 'reporting.widget_editor.data_form.metric_info.ranking',
    geoHeatMap: 'reporting.widget_editor.data_form.metric_info.geo_heat_map',
    table: 'reporting.widget_editor.data_form.metric_info.table',
    scorecard: 'reporting.widget_editor.data_form.metric_info.scorecard',
    msbar2d: 'reporting.widget_editor.data_form.metric_info.bar',
    mscolumn2d: 'reporting.widget_editor.data_form.metric_info.column',
  });

  public widgetDimensionInfoTranslate = signal<
    Record<WidgetWithDimension, string>
  >({
    pie2d: 'reporting.widget_editor.data_form.dimension_info.pie',
    doughnut2d: 'reporting.widget_editor.data_form.dimension_info.doughnut',
    mscombidy2d: 'reporting.widget_editor.data_form.dimension_info.mixed_chart',
    multiaxisline:
      'reporting.widget_editor.data_form.dimension_info.line_chart',
    msarea: 'reporting.widget_editor.data_form.dimension_info.area_chart',
    stackedcolumn2d:
      'reporting.widget_editor.data_form.dimension_info.stacked_column',
    stackedbar2d:
      'reporting.widget_editor.data_form.dimension_info.stacked_bar',
    biggestchanges:
      'reporting.widget_editor.data_form.dimension_info.biggest_changes',
    timeline: 'reporting.widget_editor.data_form.dimension_info.timeline',
    ranking: 'reporting.widget_editor.data_form.dimension_info.ranking',
    geoHeatMap: 'reporting.widget_editor.data_form.dimension_info.geo_heat_map',
    table: 'reporting.widget_editor.data_form.dimension_info.table',
    msbar2d: 'reporting.widget_editor.data_form.dimension_info.bar',
    mscolumn2d: 'reporting.widget_editor.data_form.dimension_info.column',
  });

  public widgetGoalAmountTranslate = signal<
    Record<WidgetWithGoalAmount, string>
  >({
    gauge: 'reporting.widget_editor.data_form.goal_amount_info.gauge',
    'current-goal':
      'reporting.widget_editor.data_form.goal_amount_info.current_goal',
    timeline: 'reporting.widget_editor.data_form.goal_amount_info.timeline',
  });

  public readonly datasetNoRowCount = signal<Record<string, string>>({
    '631f962b633ea51f856627c3': 'ga4',
    '61951d776fa33928df627694': 'search-console',
  });

  public fieldNoSupportedTranslate = signal<string>('');
  // Signals
  widgetDataForm = input<UntypedFormGroup>(new UntypedFormGroup({}));

  // Inputs / Outputs
  @Input() widget: Widget;
  @Input() reportPage: ReportPage;
  @Input() datasets: Array<Dataset>;
  @Input() datasetFilterCtrl: UntypedFormControl;
  @Input() newMeasureForm: UntypedFormGroup = new UntypedFormGroup({});
  @Input() newDimensionForm: UntypedFormGroup = new UntypedFormGroup({});
  @Input() ckEditorCtrl: UntypedFormControl = new UntypedFormControl();
  @Input() filterApplied: boolean;
  @Input() previewed: boolean;
  @Input() sortChange: boolean;
  @Input() loading: boolean;
  @Output() saveWidget = new EventEmitter();
  @Output() dataFormPrevious = new EventEmitter<ScopeField[]>();
  @Output() sendFilters = new EventEmitter();

  @ViewChild('dimensionTrigger') dimensionTrigger: MatMenuTrigger;
  @ViewChild('scorecardFieldTrigger') scorecardFieldTrigger: MatMenuTrigger;
  @ViewChild('ckeditor', {static: false}) ckeditor: CKEditorComponent;
  @ViewChild('conditionalMetric') conditionalMetric: ConditionalMetricComponent;
  @ViewChild('dataSets') datasetList: MatAutocomplete;

  // State
  allowMultipleFields = true;
  allowMultipleDimensions = false;
  allowMoreFields = true;
  allowMoreDimensions = true;

  // Properties
  subs: Subscription = new Subscription();
  protected onDestroySubject = new Subject<void>();
  checkAdPreview = (field: ScopeField): boolean => field.id === 'adPreview';
  checkPostPreview = (field: ScopeField): boolean => field.id === 'postPreview';
  today: number = Date.now();
  public pageConnections: string[] = [];

  selectedDataset: Dataset;
  selectedFilter: ScopeField;

  // List of items to filter
  scopes: Scope[];
  fields: ScopeField[] = [];
  sortFields: ScopeField[] = [];
  locationDimension: ScopeField[] = [];

  // Controls for the MatSelect filter keyword
  fieldFilterCtrl: UntypedFormControl = new UntypedFormControl();
  dimFilterCtrl: UntypedFormControl = new UntypedFormControl();
  dimensionFilterCtrl: UntypedFormControl = new UntypedFormControl();
  metricFilterCtrl: UntypedFormControl = new UntypedFormControl();
  scoreCardFieldFilterCtrl: UntypedFormControl = new UntypedFormControl();

  // Lists of items filtered by search keyword
  public filteredDataSets: ReplaySubject<Dataset[]> = new ReplaySubject<
    Dataset[]
  >(1);
  public filteredScopes: ReplaySubject<Scope[]> = new ReplaySubject<Scope[]>(1);
  public filteredDimensions: ReplaySubject<ScopeField[]> = new ReplaySubject<
    ScopeField[]
  >(1);
  public filteredMetrics: ReplaySubject<ScopeField[]> = new ReplaySubject<
    ScopeField[]
  >(1);

  // Static Data
  metricTypes = ['currency', 'percentage', 'decimal', 'integer', 'time'];
  excludedWidgetTypes: string[] = [
    'gallery',
    'dynamictext',
    'text',
    'title',
    'image',
    'container',
    'branding',
  ];
  allowedWidget = false;
  scorecardField = signal<ScopeField | null>(null);
  filteredFields = signal<ScopeField[]>([]);
  isDimensionField = signal<boolean>(false);

  // CKEditor
  public editor = ClassicEditor;
  public editorInstance: ClassicEditor;
  editorConfig = {
    toolbar: [
      'undo',
      'redo',
      '|',
      'bold',
      'italic',
      'underline',
      '|',
      'link',
      'alignment',
      'bulletedList',
      'numberedList',
    ],
    plugins: [
      Essentials,
      Bold,
      Italic,
      Paragraph,
      Underline,
      Undo,
      Alignment,
      Link,
      List,
      ListProperties,
      WsmDateRangeButton,
      WSMButton,
    ],
  };

  constructor(
    private notificationService: NotificationService,
    public scopeService: ScopeService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private router: Router,
    private widgetService: WidgetService,
    private helperService: HelperService
  ) {}

  @HostListener('window:onClickWsmBtn', ['$event'])
  openMetricsModalPopup(): void {
    this.openDynamicMetric();
  }

  @HostListener('window:onClickWsmDateRangeButton', ['$event'])
  openDateRangeModalPopup(): void {
    this.openDateRangeDialog();
  }
  ngOnInit(): void {
    if (
      this.widget.dimensions === undefined ||
      this.widget.dimensions === null
    ) {
      this.widget.dimensions = [];
    }

    if (this.widget.metrics === undefined || this.widget.metrics === null) {
      this.widget.metrics = [];
    }

    if (this.widget.filters === undefined || this.widget.filters === null) {
      this.widget.filters = [];
    }

    if (
      this.widget.widgetType === 'geoHeatMap' &&
      !this.widget.relatedDimensions
    ) {
      this.widget.relatedDimensions = [];
    }

    this.allowedWidget = this.excludedWidgetTypes.includes(
      this.widget.widgetType
    );

    if (this.reportPage && this.reportPage?.connections) {
      this.pageConnections = this.reportPage.connections?.map(
        (c: Connection) => c.id!
      );
    }

    if (this.widget?.widgetType === 'scorecard') {
      if (
        this.widget.dimensions !== undefined &&
        this.widget.dimensions.length > 0
      ) {
        this.scorecardField.set(this.widget.dimensions[0]);
        this.isDimensionField.set(true);
      }
      if (this.widget.metrics !== undefined && this.widget.metrics.length > 0) {
        this.scorecardField.set(this.widget.metrics[0]);
        this.isDimensionField.set(false);
      }
    }
    this.fieldNoSupportedTranslate.set(
      this.translate.instant(
        'reporting.widget_editor.data_form.field_not_supported'
      )
    );
    this.initializeFormData();
  }

  ngOnDestroy(): void {
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.datasets) {
      this.updateDatasetInfo();
    }
  }

  onSaveWidget(): void {
    this.saveWidget.emit(true);
  }

  /**
   * Initialize main data according to the datasets.
   */
  updateDatasetInfo(): void {
    if (this.datasets?.length >= 0) {
      this.filteredDataSets.next(this.datasets.slice());
      this.setWidgetFieldsData();
      this.changeFieldsOnSelection();

      if (this.scopes !== undefined) {
        this.filteredScopes.next(this.scopes.slice());
      }
    }

    if (this.widget?.dataset) {
      this.selectedDataset =
        this.datasets?.find(
          (dataSet: Dataset) => dataSet._id === this.widget.dataset
        ) || {};
    }
  }

  private addFullSortFields(): void {
    if (this.fields !== undefined && this.widget.widgetType === 'gallery') {
      this.sortFields = [];
      const dimensions = this.fields.filter(
        (field) => !this.metricTypes.includes(field.dataType!)
      );

      let metrics = this.fields.filter((field) =>
        this.metricTypes.includes(field.dataType!)
      );

      if (dimensions?.length > 0) {
        this.sortFields = [...this.sortFields, ...dimensions];
      }

      if (metrics?.length > 0) {
        metrics = metrics
          .filter((metric: ScopeField) => metric?.defaultAggregation)
          .map((metric: ScopeField) => {
            metric.aggregation = metric.defaultAggregation;
            return metric;
          });
        this.sortFields = [...this.sortFields, ...metrics];

        if (
          this.widget?.metrics &&
          this.widget?.metrics?.length > 0 &&
          this.widget?.sortField
        ) {
          this.sortFields = [];
          this.widget.metrics?.forEach((m: ScopeField): void => {
            this.sortFields.push(m);
          });
          this.addSortBy();
        }
      }

      this.widget.sortField = this.widget?.sortField || 'clicks_sum';
    }
  }

  /**
   * Initialize all the controls of the widget data form.
   */
  initializeFormData(): void {
    this.widgetDataForm().addControl('type', new UntypedFormControl('data'));
    this.widgetDataForm().addControl(
      'dataset',
      new UntypedFormControl(this.widget.dataset, Validators.required)
    );
    this.widgetDataForm().addControl(
      'resultLimit',
      new UntypedFormControl(this.widget.resultLimit, [
        Validators.pattern('^[0-9]*$'),
      ])
    );
    this.widgetDataForm().addControl(
      'hidePaginator',
      new UntypedFormControl(this.widget.hidePaginator)
    );
    this.widgetDataForm().addControl('metrics', new UntypedFormControl(null));
    this.widgetDataForm().addControl(
      'dimensions',
      new UntypedFormControl(null)
    );
    this.widgetDataForm().addControl('filters', new UntypedFormControl(null));
    this.widgetDataForm().addControl(
      'filterLogic',
      new UntypedFormControl(null)
    );
    this.widgetDataForm().addControl('dateRange', new UntypedFormControl(null));
    this.widgetDataForm().addControl(
      'totalPosition',
      new UntypedFormControl(this.widget?.totalPosition ?? 'bottom')
    );

    /**
     * dataset is not required for basic dynamictext
     */
    if (
      this.widget.widgetType === 'dynamictext' &&
      this.widget.dynamicTextEdit === 'basic'
    ) {
      this.widgetDataForm().controls.dataset.removeValidators(
        Validators.required
      );
    }

    this.widgetDataForm().updateValueAndValidity();

    this.setDefaultConfigs();
    this.listenControlSubs();
  }

  setDefaultConfigs(): void {
    this.dataFormPrevious.emit(this.widgetDataForm().value);

    this.newMeasureForm = new UntypedFormGroup({
      fieldIsNew: new UntypedFormControl(false),
      fieldId: new UntypedFormControl(
        {value: null, disabled: !this.widget.scope},
        [Validators.required]
      ),
      aggregation: new UntypedFormControl(null, [Validators.required]),
      useSecondaryAxis: new UntypedFormControl(false),
      shortenNumbers: new UntypedFormControl(false),
    });

    this.newDimensionForm = new UntypedFormGroup({
      fieldIsNew: new UntypedFormControl(false),
      fieldId: new UntypedFormControl(
        {value: null, disabled: !this.widget.scope},
        [Validators.required]
      ),
      dateConfiguration: new UntypedFormGroup({
        dateGroup: new UntypedFormControl(''),
        dateFormat: new UntypedFormControl(''),
      }),
    });

    if (this.widget.widgetType === 'ranking') {
      this.newDimensionForm.addControl(
        'showAs',
        new UntypedFormControl('phrase')
      );
    }

    if (
      this.widget.widgetType === 'dynamictext' ||
      this.widget.widgetType === 'text'
    ) {
      this.widgetService.setChangeDynamicTextValue({
        isInitialValue: true,
        widgetId: this.widget.id,
        from: 'editor',
      });
      this.widgetDataForm().addControl(
        'richText',
        new UntypedFormControl(this.widget.textData?.originalHtml)
      );
      const richTextControl = this.widgetDataForm().get('richText');
      if (richTextControl) {
        richTextControl.valueChanges
          .pipe(takeUntil(this.onDestroySubject))
          .subscribe((value) => {
            this.validateMetricsInsideTheEditor(value);
            if (this.widget.textData) {
              // this.widget.textData.innerHtml = value;
              this.widget.isPreview = false;
              this.widgetService.setChangeDynamicTextValue({
                isInitialValue: false,
                from: 'editor',
                widgetId: this.widget.id,
                newText: {innerHtml: value, originalHtml: value},
              });
            }
            if (!this.widget.isEditStyle) {
              this.widget.isEditStyle = true;
            }
          });
      }
    }

    if (this.widget.widgetType === 'dynamictext') {
      this.editorConfig.toolbar = [
        ...this.editorConfig.toolbar,
        ...['|', 'wsm-date-range-button', 'wsmbutton'],
      ];
      this.ckEditorCtrl.setValue(this.widget?.dynamicTextEdit || 'basic');
    }

    if (
      this.widget.widgetType === 'dynamictext' &&
      this.ckEditorCtrl.value === 'advanced'
    ) {
      this.widgetDataForm().addControl(
        'advancedRichText',
        new UntypedFormControl(this.widget?.dynamicConditionalData || '')
      );
    }

    switch (this.widget.widgetType) {
      case 'table':
        this.addSortBy();
        this.allowMultipleDimensions = true;
        break;
      case 'scorecard':
      case 'geoHeatMap':
        this.widgetDataForm()
          .get('resultLimit')
          ?.setValue(this.widget.resultLimit ? this.widget.resultLimit : 20);
        this.addSortBy();
        this.allowMultipleFields = false;
        break;
      case 'ranking':
        this.addSortBy();
        this.allowMultipleFields = false;
        break;
      case 'gallery':
        this.addSortBy();
        this.allowMultipleFields = false;

        if (this.widgetDataForm().get('dimensions')) {
          this.widgetDataForm().removeControl('dimensions');
        }

        this.widget.dimensions =
          this.widget?.dimensions && this.widget?.dimensions?.length > 0
            ? this.widget.dimensions
            : [];
        this.widget.metrics =
          this.widget?.metrics && this.widget?.metrics?.length > 0
            ? this.widget.metrics
            : [];

        this.datasetFilterCtrl.setValidators([
          Validators.required,
          ValidateAdDatasetFields,
        ]);
        this.datasetFilterCtrl.updateValueAndValidity();
        break;
      case 'chart':
        this.widgetDataForm().addControl(
          'chartType',
          new UntypedFormControl(null)
        );
        this.widgetDataForm().get('chartType')?.setValue(this.widget.chartType);

        if (this.widget.chartType === 'doughnut2d') {
          this.allowMultipleFields = false;
        }

        if (
          this.widget.chartType === 'stackedcolumn2d' ||
          this.widget.chartType === 'stackedbar2d'
        ) {
          this.allowMultipleDimensions = true;
          this.allowMultipleFields = false;
        }
        break;
      case 'biggestchanges':
        this.allowMultipleFields = false;
        this.addBiggestChangesSortBy();
        break;
      case 'gauge':
      case 'current-goal':
        this.allowMultipleFields = false;
        this.widgetDataForm().addControl(
          'goalAmount',
          new UntypedFormControl(this.widget.goalAmount, Validators.required)
        );
        this.widgetDataForm().updateValueAndValidity();
        break;
      case 'timeline':
        this.widgetDataForm().addControl(
          'goalAmount',
          new UntypedFormControl(this.widget.goalAmount, Validators.required)
        );
        this.addSortBy();
        break;
    }

    if (this.widget.widgetType !== 'gallery') {
      this.sortFields = [];
      if (
        this.widget.widgetType !== 'ranking' &&
        this.widget.widgetType !== 'geoHeatMap'
      ) {
        this.widget.dimensions?.forEach((m: ScopeField) => {
          this.sortFields.push(m);
        });
      }

      this.widget.metrics?.forEach((m: ScopeField) => {
        this.sortFields.push(m);
      });
    }

    this.blockOrAllowMoreFields();
  }

  /**
   * Detect changes in the controls to update the widget properties.
   */
  listenControlSubs(): void {
    this.subs.add(
      this.widgetDataForm().valueChanges.subscribe(
        () => (this.previewed = false)
      )
    );

    // Hide Paginator
    this.subs.add(
      this.widgetDataForm()
        .get('hidePaginator')
        ?.valueChanges.subscribe((value) => {
          this.widget.hidePaginator = value;
        })
    );

    // Total Position
    this.subs.add(
      this.widgetDataForm()
        .get('totalPosition')
        ?.valueChanges?.subscribe((value: string) => {
          this.widget.totalPosition = value;
        })
    );

    // CKEditor Control
    this.subs.add(
      this.ckEditorCtrl.valueChanges
        .pipe(distinctUntilChanged())
        // Makes sure the value has actually changed.
        .subscribe((res: string) => {
          this.widget.dynamicTextEdit = res;
          const widgetId = this.widget.id;
          this.onChangesDynamicTextValues(res, widgetId);
          const hasAdvancedRichText =
            this.widgetDataForm().contains('advancedRichText');
          if (!hasAdvancedRichText) {
            this.widgetDataForm().addControl(
              'advancedRichText',
              new UntypedFormControl(this.widget?.dynamicConditionalData || '')
            );
          }
          if (res === 'basic') {
            this.widgetDataForm().controls.dataset.clearValidators();
          }

          if (res === 'advanced') {
            this.widgetDataForm().controls.dataset.addValidators(
              Validators.required
            );

            if (!this.widget.dynamicConditionalData) {
              const newText = this.translate.instant(
                'reporting.widget_editor.data_form.rich_advance_message'
              );
              this.widgetService.setChangeDynamicTextValue({
                isInitialValue: false,
                from: 'editor',
                widgetId: this.widget.id,
                newText: {innerHtml: newText, originalHtml: newText},
              });
            }
          }
          this.widgetDataForm().controls.dataset.updateValueAndValidity();
        })
    );

    const dateConfiguration = new UntypedFormGroup({
      dateGroup: new UntypedFormControl('', [Validators.required]),
      dateFormat: new UntypedFormControl('', [Validators.required]),
    });

    this.subs.add(
      dateConfiguration.get('dateGroup')?.valueChanges.subscribe(() => {
        dateConfiguration.get('dateFormat')?.setValue('');
      })
    );

    this.subs.add(
      this.newDimensionForm.get('fieldId')?.valueChanges.subscribe((value) => {
        if (value) {
          if (value && value?.dataType === 'date') {
            this.newDimensionForm.setControl(
              'dateConfiguration',
              dateConfiguration
            );
            this.newDimensionForm
              .get('dateConfiguration')
              ?.addValidators([Validators.required]);
            this.newDimensionForm
              .get('dateFormat')
              ?.addValidators([Validators.required]);
          } else if (
            value &&
            (value.id === 'adPreview' || value.id === 'postPreview')
          ) {
            this.newDimensionForm.setControl(
              'darkModeEnabled',
              new UntypedFormControl(
                value?.darkModeEnabled ? value?.darkModeEnabled : false
              )
            );
          } else {
            this.newDimensionForm.get('dateConfiguration')?.reset();
            this.newDimensionForm.removeControl('dateConfiguration');
            this.newDimensionForm.removeControl('darkModeEnabled');
          }

          this.newDimensionForm.updateValueAndValidity();
        }
      })
    );

    this.newMeasureForm
      .get('fieldId')
      ?.valueChanges.pipe(pairwise())
      .subscribe(([prevField, nextField]) => {
        if (nextField) {
          const aggValue = this.newMeasureForm.get('aggregation')?.value;
          const selectedField: ScopeField = this.fields.filter(
            (field: ScopeField) => field.id === nextField.id
          )[0];

          if (!aggValue && selectedField?.defaultAggregation) {
            this.newMeasureForm
              .get('aggregation')
              ?.setValue(selectedField.defaultAggregation?.trim());
          } else {
            if (
              typeof prevField === 'object' &&
              typeof nextField === 'object' &&
              selectedField?.defaultAggregation
            ) {
              this.newMeasureForm
                .get('aggregation')
                ?.setValue(selectedField.defaultAggregation);
            }
          }
        }
      });

    // Listen for search field value changes
    this.datasetFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe(() => {
        this.filterData('dataset');
      });

    this.dimensionFilterCtrl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.onDestroySubject)
      )
      .subscribe(() => {
        this.filterData('field', 'dim');
        this.changeFieldsOnSelection();
      });

    this.metricFilterCtrl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.onDestroySubject)
      )
      .subscribe(() => {
        this.filterData('field', 'mtr');
        this.changeFieldsOnSelection();
      });

    this.scoreCardFieldFilterCtrl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.onDestroySubject)
      )
      .subscribe((val) => {
        if (typeof val === 'string') {
          const search = val.toLowerCase();
          this.setWidgetFilteredFields(search);
        }
      });

    this.fieldFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe(() => {
        this.filterData('field');
      });

    this.dimFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe(() => {
        this.filterData('filter');
      });
  }

  onChangesDynamicTextValues(type: string, widgetId: string): void {
    if (type === 'basic' && this.widget.textData) {
      this.widgetService.setChangeDynamicTextValue({
        isInitialValue: false,
        from: 'editor',
        widgetId,
        newText: {
          innerHtml: this.widget.textData.innerHtml,
          originalHtml: this.widget.textData?.originalHtml,
        },
      });
    }
    if (type === 'advanced' && this.widget.newPreviewText) {
      this.widgetService.setChangeDynamicTextValue({
        isInitialValue: false,
        from: 'editor',
        widgetId,
        newText: {
          innerHtml: this.widget.newPreviewText.innerHtml,
          originalHtml: this.widget.newPreviewText?.originalHtml,
        },
      });
    }
  }

  setLocationScope(): void {
    const locationScope = this.selectedDataset.scopes?.filter((scope) =>
      scope.fields.some((field) => field.dataType === 'location')
    );
    this.scopes = [...locationScope!];
    this.filteredScopes.next(this.scopes.slice());
  }

  chartTypeChanged(chartType: string): void {
    this.widget.chartType = chartType;
    this.widget.chartData!.chartType = chartType;
    if (chartType === 'funnel') {
      if (this.widgetDataForm().get('dimensions')) {
        this.widgetDataForm().removeControl('dimensions');
      }

      this.widget.dimensions = [];
      this.allowMultipleFields = true;
      this.updateSortFieldOnChangeChart();
      this.blockOrAllowMoreFields();
    } else {
      if (!this.widgetDataForm().get('dimensions')) {
        this.widgetDataForm().addControl(
          'dimensions',
          new UntypedFormControl(null)
        );
        this.widgetDataForm().updateValueAndValidity();
      }
      this.allowMultipleDimensions =
        this.widget?.chartType === 'stackedcolumn2d' ||
        this.widget?.chartType === 'stackedbar2d';

      if (
        this.widget?.chartType !== 'stackedcolumn2d' &&
        this.widget?.chartType !== 'stackedbar2d' &&
        this.widget?.dimensions &&
        this.widget?.dimensions?.length > 1
      ) {
        // Keep just one dimension in other charts
        this.widget.dimensions = [this.widget.dimensions[0]];
        this.allowMultipleFields = false;
      }

      if (this.widget.chartType === 'doughnut2d') {
        this.allowMultipleFields = false;
        // Keep just one metric in this chart
        if (this.widget?.metrics && this.widget?.metrics?.length > 1) {
          this.widget.metrics = [this.widget.metrics[0]];
        }
      } else {
        // Allow to add more than one metric
        this.allowMultipleFields = true;
      }
      this.updateSortFieldOnChangeChart();
      this.blockOrAllowMoreFields();
    }

    if (
      [
        'msarea',
        'multiaxisline',
        'mscombidy2d',
        'msbar2d',
        'mscolumn2d',
        'doughnut2d',
        'pie2d',
        'stackedcolumn2d',
        'stackedbar2d',
      ].includes(chartType)
    ) {
      this.addSortBy();
    } else {
      if (
        this.widgetDataForm().get('sortField') &&
        this.widgetDataForm().get('sortDirection')
      ) {
        this.widgetDataForm().removeControl('sortField');
        this.widgetDataForm().removeControl('sortDirection');
        this.widgetDataForm().updateValueAndValidity();
      }
    }
  }

  private updateSortFieldOnChangeChart(): void {
    // Update sort fields based on new metrics and dimensions
    this.sortFields = [];

    this.widget.dimensions?.forEach((m: ScopeField) => {
      this.sortFields.push(m);
    });

    this.widget.metrics?.forEach((m: ScopeField) => {
      this.sortFields.push(m);
    });
  }

  blockOrAllowMoreFields(): void {
    this.allowMoreFields = !(
      !this.allowMultipleFields &&
      this.widget?.metrics &&
      this.widget?.metrics?.length >= 1
    );
    this.allowMoreDimensions = !(
      !this.allowMultipleDimensions &&
      this.widget?.dimensions &&
      this.widget?.dimensions?.length >= 1
    );

    if (this.widget?.widgetType === 'scorecard') {
      this.allowMoreFields = !this.scorecardField();
    }

    if (
      this.widget.widgetType === 'chart' &&
      (this.widget?.chartType === 'stackedcolumn2d' ||
        this.widget?.chartType === 'stackedbar2d')
    ) {
      this.allowMoreDimensions = (this.widget?.dimensions &&
        this.widget?.dimensions?.length < 2) as boolean;
      this.allowMultipleDimensions = (this.widget?.dimensions &&
        this.widget?.dimensions?.length < 2) as boolean;

      this.allowMoreFields = (this.widget?.metrics &&
        this.widget?.metrics?.length < 1) as boolean;
    }
  }

  addSortBy(): void {
    if (
      !this.widgetDataForm().get('sortField') &&
      !this.widgetDataForm().get('sortDirection')
    ) {
      this.widgetDataForm().addControl(
        'sortField',
        new UntypedFormControl(this.widget.sortField)
      );
      this.widgetDataForm().addControl(
        'sortDirection',
        new UntypedFormControl(this.widget.sortDirection || 'asc')
      );
      this.widgetDataForm().updateValueAndValidity();

      this.subs.add(
        this.widgetDataForm()
          .get('sortField')
          ?.valueChanges.subscribe((selectedValue) => {
            this.widget.sortField = selectedValue;
          })
      );

      this.subs.add(
        this.widgetDataForm()
          .get('sortDirection')
          ?.valueChanges.subscribe((selectedValue) => {
            this.widget.sortDirection = selectedValue;
            this.sortChange =
              selectedValue !== this.widgetDataForm().value.sortDirection;
          })
      );
    }
  }

  addBiggestChangesSortBy(): void {
    this.widgetDataForm().addControl(
      'sortField',
      new UntypedFormControl(this.widget.sortCustom || 'delta')
    );
    this.widgetDataForm().addControl(
      'sortDirection',
      new UntypedFormControl(this.widget.sortDirection || 'asc')
    );
  }

  setGallerySortBy(newField: ScopeField): void {
    if (this.sortFields?.length > 0 && newField) {
      if (newField?.aggregation) {
        this.widgetDataForm()
          .get('sortField')
          ?.setValue(`${newField?.id}_${newField.aggregation}`);
      } else {
        this.widgetDataForm().get('sortField')?.setValue(`${newField?.id}`);
      }
      this.widgetDataForm().get('sortDirection')?.setValue('desc');
    }
  }

  displayDataset(dataset: Dataset): string {
    return dataset && dataset.name ? dataset.name : '';
  }

  displayDimension(dimension: ScopeField): string {
    return dimension && dimension.name ? dimension.name : '';
  }

  displayMetric(metric: ScopeField): string {
    return metric && metric.name ? metric.name : '';
  }

  displayScorecardField(field: ScopeField): string {
    return field && field.name ? field.name : '';
  }

  // Filter elements to search...
  filterData(type: string, fieldType?: string): void {
    if (!this.datasets) {
      return;
    }

    // Get the search keyword
    let search;

    if (type === 'dataset') {
      search = this.datasetFilterCtrl.value;

      if (typeof search !== 'string') {
        this.setFormValue('dataset', this.datasetFilterCtrl.value);
      }

      if (search === '') {
        this.datasetFilterCtrl.reset();
        this.widget.dataset = '';
      }
    } else if (type === 'field') {
      if (fieldType === 'dim') {
        search = this.dimensionFilterCtrl?.value;

        if (typeof search !== 'string') {
          this.setDimensionFormValue(
            'fieldId',
            this.dimensionFilterCtrl?.value
          );

          if (
            this.dimensionFilterCtrl?.value?.dataType === 'date' &&
            !this.dimensionFilterCtrl?.value?.dateConfiguration
          ) {
            // Add default values in date dimension
            this.newDimensionForm
              ?.get('dateConfiguration.dateGroup')
              ?.setValue('date');
            this.newDimensionForm
              ?.get('dateConfiguration.dateFormat')
              ?.setValue('date');
          }
        }
      } else if (fieldType === 'mtr') {
        search = this.metricFilterCtrl.value;

        if (typeof search !== 'string') {
          this.setMeasureFormValue('fieldId', this.metricFilterCtrl.value);
        }
      }
    } else if (type === 'filter') {
      search = this.dimFilterCtrl.value;
    }

    if (!search) {
      this.filteredDataSets.next(this.datasets.slice());

      if (this.selectedDataset) {
        this.filteredScopes.next(this.selectedDataset.scopes!.slice());
      }
      this.setWidgetFilteredFields();
      return;
    } else {
      search = typeof search === 'string' ? search.toLowerCase() : search;
    }

    // Filter the data
    if (type === 'dataset') {
      let datasetList = this.datasets;
      if (typeof search === 'string' && search) {
        datasetList = this.datasets.filter(
          (ds) => ds.name && ds.name?.toLowerCase().indexOf(search) > -1
        );
      }
      this.filteredDataSets.next(datasetList);
    } else if (type === 'field' || type === 'filter') {
      this.setWidgetFilteredFields(search);
    }
  }

  // Update control value from Widget Data Form
  setFormValue(controlName: string, value: any): void {
    if (value) {
      this.widgetDataForm().markAsDirty();
      this.widgetDataForm().controls[controlName].setValue(value);
    }
  }

  // Update control value from Measure Form
  setMeasureFormValue(controlName: string, value: any): void {
    if (value) {
      this.newMeasureForm.controls[controlName].setValue(value);
    }
  }

  // Update control value from Dimension Form
  setDimensionFormValue(controlName: string, value: any): void {
    if (value) {
      this.newDimensionForm.controls[controlName].setValue(value);
    }
  }

  onDropMeasure(event: CdkDragDrop<ScopeField[]>, type: string): void {
    this.widgetDataForm().markAsDirty();
    if (type === 'metric') {
      moveItemInArray(
        this.widget?.metrics as ScopeField[],
        event.previousIndex,
        event.currentIndex
      );
      this.widgetDataForm().get('metrics')?.setValue(this.widget?.metrics);
    } else if (type === 'dimension') {
      moveItemInArray(
        this.widget?.dimensions as ScopeField[],
        event.previousIndex,
        event.currentIndex
      );
      this.widgetDataForm().get('dimensions')?.setValue(this.widget?.metrics);
    }
  }

  updateDimensionForm(field: ScopeField | null): void {
    if (field) {
      if (field.dataType === 'location') {
        this.widget.relatedDimensions = [];
      }
      this.newDimensionForm.get('fieldId')?.setValue(field);
      this.newDimensionForm.get('fieldIsNew')?.setValue(false);
      this.dimensionFilterCtrl.setValue(field);

      if (field.dataType === 'adPreview') {
        this.newDimensionForm
          .get('darkModeEnabled')
          ?.setValue(field.darkModeEnabled, {emitEvent: false});
      }

      if (field.dataType === 'date') {
        this.newDimensionForm
          .get('dateConfiguration')
          ?.setValue(field.dateConfiguration);
      }

      if (field.dataType === 'string' && this.widget.widgetType === 'ranking') {
        this.newDimensionForm.get('showAs')?.setValue(field.showAs);
      }
    } else {
      this.newDimensionForm.get('fieldId')?.reset();
      this.fieldFilterCtrl.reset();
      this.newDimensionForm.get('fieldIsNew')?.setValue(true);
    }
  }

  updateMetricForm(field: ScopeField | null): void {
    this.newMeasureForm.reset();

    if (field !== null) {
      this.newMeasureForm.get('fieldId')?.setValue(field.id);
      this.newMeasureForm.get('aggregation')?.setValue(field.aggregation);
      this.newMeasureForm
        .get('useSecondaryAxis')
        ?.setValue(field.useSecondaryAxis);
      this.newMeasureForm.get('fieldIsNew')?.setValue(false);
      this.newMeasureForm.get('shortenNumbers')?.setValue(field.shortenNumbers);

      const mtrFilter = this.setMtrFltValue(field.name);
      this.metricFilterCtrl.setValue(mtrFilter);
    } else {
      this.newMeasureForm.get('fieldIsNew')?.setValue(true);
      this.metricFilterCtrl.reset();
    }
  }

  removeMeasure(field: ScopeField, isDimension: boolean): void {
    this.widgetDataForm().markAsDirty();

    if (field !== null) {
      if (isDimension) {
        this.widget?.dimensions?.forEach((f: ScopeField, k: number) => {
          if (f.id === field.id) {
            this.widget.dimensions?.splice(k, 1);
            this.widgetDataForm()
              .get('dimensions')
              ?.setValue(this.widget.dimensions);
          }
        });

        // ONLY FOR GEO-HEAT-MAP WIDGET
        if (this.widget.widgetType === 'geoHeatMap') {
          this.widget.relatedDimensions = [];
        }
      } else {
        this.widget?.metrics?.forEach((f: ScopeField, k: number) => {
          if (f.id === field.id) {
            this.widget.metrics?.splice(k, 1);
            this.widgetDataForm().get('metrics')?.setValue(this.widget.metrics);
          }
        });
      }

      if (this.widget.widgetType === 'scorecard') {
        this.scorecardField.set(null);
        this.scoreCardFieldFilterCtrl.reset();
      }

      this.previewed = false;
      this.blockOrAllowMoreFields();
      this.updateSortFields();
      this.changeFieldsOnSelection();
    }
  }

  dimensionMenuClose(): void {
    this.newDimensionForm.get('fieldId')?.reset();
    this.dimensionFilterCtrl.reset();
  }

  onDimensionChanged(index: number): void {
    this.widgetDataForm().markAsDirty();
    if (!this.newDimensionForm.valid) {
      return;
    }

    const fieldId = this.newDimensionForm.get('fieldId')?.value.id;
    let newField = {} as ScopeField;

    this.fields.forEach((field: ScopeField) => {
      if (field.id === fieldId) {
        newField = Object.assign(newField, field);
        if (field.dataType === 'date') {
          newField.dateConfiguration =
            this.newDimensionForm.get('dateConfiguration')?.value;
        } else if (field.dataType === 'adPreview') {
          newField.darkModeEnabled =
            this.newDimensionForm.get('darkModeEnabled')?.value;
        } else if (
          field.dataType === 'string' &&
          this.widget.widgetType === 'ranking'
        ) {
          newField.showAs = this.newDimensionForm.get('showAs')?.value;
        }
      }
    });

    if (index >= 0) {
      this.widget.dimensions![index] = newField;
    } else {
      this.widget?.dimensions!.push(newField);
    }

    if (this.widget?.widgetType === 'scorecard') {
      this.scorecardField.set(newField as ScopeField);
      this.widget.metrics = [];
      this.scorecardFieldTrigger?.closeMenu();
    }
    // only for geo-heat-map
    this.onHandleLocationRelatedDimension(newField);

    this.blockOrAllowMoreFields();
    this.widgetDataForm().get('dimensions')?.setValue(this.widget.dimensions);
    this.dimensionFilterCtrl.reset();
    this.dimensionTrigger?.closeMenu();
    this.updateSortFields();
  }

  onHandleLocationRelatedDimension(newField: ScopeField): void {
    if (this.widget.widgetType === 'geoHeatMap') {
      this.locationDimension = this.fields.filter(
        (field: ScopeField) => field.dataType === 'location'
      );

      const pivotLocationDimension = [...this.locationDimension];
      pivotLocationDimension.forEach((field) => {
        switch (newField.administrativeArea) {
          case 'region':
            if (field.administrativeArea === 'country') {
              this.widget.relatedDimensions!.push(field);
            }
            break;
          case 'city':
            if (
              field.administrativeArea === 'country' ||
              field.administrativeArea === 'region'
            ) {
              this.widget.relatedDimensions!.push(field);
            }
            break;
        }
      });
    }
  }

  onMeasureChanged(index): void {
    this.widgetDataForm().markAsDirty();
    if (this.newMeasureForm.invalid) {
      return;
    }

    const fieldIdControl = this.newMeasureForm.get('fieldId');
    const fieldId = fieldIdControl?.value.id
      ? fieldIdControl.value.id
      : fieldIdControl?.value;

    let newField = {} as ScopeField;
    this.fields.forEach((field: ScopeField) => {
      if (field.id === fieldId) {
        newField = Object.assign(newField, field);
        newField.aggregation = this.newMeasureForm.get('aggregation')?.value;
        newField.useSecondaryAxis =
          this.newMeasureForm.get('useSecondaryAxis')?.value;
        newField.shortenNumbers =
          this.newMeasureForm.get('shortenNumbers')?.value;
      }
    });

    if (index >= 0) {
      this.widget.metrics![index] = newField;
    } else {
      if (!this.metricHasAlreadyExist(newField)) {
        this.widget?.metrics!.push(newField);
      }
    }

    if (this.widget?.widgetType === 'scorecard') {
      this.scorecardField.set(newField as ScopeField);
      this.widget.dimensions = [];
      this.scorecardFieldTrigger?.closeMenu();
    }

    this.blockOrAllowMoreFields();
    this.widgetDataForm().get('metrics')?.setValue(this.widget.metrics);
    this.newMeasureForm.reset();
    this.metricFilterCtrl.reset();
    this.updateSortFields();

    if (this.widget.widgetType === 'gallery') {
      this.setGallerySortBy(newField);
    }
  }

  // ADR-2948: Row Count Feature
  onScorecardFieldChanged(event: MatAutocompleteSelectedEvent): void {
    const scorecardFieldSelected = event.option.value as ScopeField;
    this.isDimensionField.set(
      !this.metricTypes.includes(scorecardFieldSelected?.dataType as string)
    );
    if (!this.isDimensionField()) {
      this.newMeasureForm.reset();
      this.metricFilterCtrl.setValue(scorecardFieldSelected);
      this.newMeasureForm.get('fieldId')?.setValue(scorecardFieldSelected);
      this.newDimensionForm.reset();
    }
    if (this.isDimensionField()) {
      this.newDimensionForm.reset();
      this.dimensionFilterCtrl.setValue(scorecardFieldSelected);
      this.newDimensionForm.get('fieldId')?.setValue(scorecardFieldSelected);
      this.newMeasureForm.reset();
    }
  }

  updateScorecardField(field: ScopeField | null): void {
    if (field) {
      this.scorecardField.set(field);
      this.isDimensionField.set(
        !this.metricTypes.includes(field.dataType as string)
      );
      this.isDimensionField()
        ? this.updateDimensionForm(field)
        : this.updateMetricForm(field);

      this.scoreCardFieldFilterCtrl.setValue(field);
    }
    if (!field) {
      this.scorecardField.set(null);
      this.isDimensionField.set(false);
      this.newMeasureForm.get('fieldIsNew')?.setValue(true);
      this.newDimensionForm.get('fieldId')?.reset();
      this.scoreCardFieldFilterCtrl.reset();
    }
  }

  updateSortFields(): void {
    this.sortFields = [];
    this.widget?.dimensions?.forEach((m: ScopeField) => {
      if (
        this.widget?.widgetType !== 'gallery' &&
        this.widget?.widgetType !== 'ranking' &&
        this.widget?.widgetType !== 'biggestchanges' &&
        this.widget?.widgetType !== 'geoHeatMap'
      ) {
        this.sortFields.push(m);
      }
    });

    this.widget?.metrics?.forEach((m: ScopeField) => {
      if (this.widget?.widgetType !== 'biggestchanges') {
        this.sortFields.push(m);
      }
    });

    this.addDefaultSortBy();
  }

  setMtrFltValue(name: string): ScopeField | undefined {
    return this.fields?.find(
      (field: ScopeField) => field?.name?.toLowerCase() === name?.toLowerCase()
    );
  }

  metricHasAlreadyExist(field: ScopeField): boolean {
    return this.widget?.metrics!.some(
      (el: ScopeField) =>
        el.name === field.name && el.aggregation === field.aggregation
    );
  }

  checkIfArray(value): boolean {
    return Array.isArray(value);
  }

  removeFilter(
    filter: ReportFilter,
    index: number,
    orFilter?: ReportFilter
  ): void {
    if (filter && this.widget.filters) {
      if (filter?.expressions && orFilter) {
        filter.expressions.splice(filter.expressions.indexOf(orFilter), 1);

        if (filter.expressions?.length <= 0 && index) {
          this.widget.filters?.splice(index, 1);
        }
      } else {
        this.widget.filters?.splice(this.widget.filters.indexOf(filter), 1);
      }

      this.widgetDataForm().markAsDirty();
      this.widgetDataForm().get('filters')?.setValue(this.widget.filters);
      this.changeFieldsOnSelection();
    }
  }

  onFilterApplied(data, index, subIndex?): void {
    this.widgetDataForm().markAsDirty();
    this.filterApplied = true;
    const newFilterField = {} as ReportFilter;

    this.fields.forEach((f: ScopeField): void => {
      if (f.id === data?.filterField?.id || f.id === data?.filterField) {
        newFilterField.fieldId = f?.id ? f.id : null;
        newFilterField.fieldName = f.name;
        newFilterField.operator = data?.filterOperator;
        if (
          f.hasPossibleValues ||
          (f.possibleValues && f.possibleValues.length > 0)
        ) {
          if (typeof data.filterValue === 'string') {
            newFilterField.value = data.filterValue;
          }
          if (typeof data.filterValue !== 'string') {
            newFilterField.labels = data?.filterValue;
          }
        }
        if (
          !f.hasPossibleValues &&
          f.possibleValues &&
          f.possibleValues.length === 0
        ) {
          newFilterField.value = data?.filterValue;
        }
        if ('filterLevel' in data) {
          newFilterField.isPropagable = data?.filterLevel;
        }
      }
    });

    if (newFilterField.fieldId !== undefined) {
      if (data.filterIsNew) {
        this.widget?.filters!.push(newFilterField);
        this.translate
          .get('notifications.filter_applied')
          .subscribe((res: string) => {
            this.notificationService.success(res, 5000);
          });
      } else {
        if (index >= 0) {
          if (subIndex >= 0) {
            this.widget.filters![index].expressions![subIndex] = newFilterField;
          } else {
            this.widget.filters![index] = newFilterField;
          }
        }
        this.translate
          .get('notifications.filter_updated')
          .subscribe((res: string) => {
            this.notificationService.success(res, 5000);
          });
      }
      this.widgetDataForm().get('filters')?.setValue(this.widget.filters);
    }

    this.changeFieldsOnSelection();
  }

  addFilterLogic(): void {
    const dialogRef = this.dialog.open(FilterLogicComponent, {
      data: {
        filters: [...this.widget.filters!],
        availableFilters: [...this.fields],
        isFromWidgetEditor: true,
        isPage: false,
        fromWidget: true,
        connections: this.pageConnections || [],
      },
      width: '800px',
    });

    const dialogSub = this.router.events.subscribe(() => {
      dialogRef.close();
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res) {
          this.widgetDataForm().markAsDirty();
          this.widgetDataForm()
            .get('filterLogic')
            ?.setValue(this.widget.filters);
          this.widget.filters = res;
          this.changeFieldsOnSelection();
        }
        dialogSub.unsubscribe();
      });
  }

  toggleSortOrder(): void {
    this.widgetDataForm().markAsDirty();
    const currentSort = this.widgetDataForm().get('sortDirection')?.value;
    this.widgetDataForm()
      .get('sortDirection')
      ?.setValue(currentSort === 'asc' ? 'desc' : 'asc');
  }

  onDateSelection(event: ReportDateRange): void {
    this.widgetDataForm().markAsDirty();
    if (event && event?.current?.relative) {
      this.widget.dateRange = event;
      this.widgetDataForm().get('dateRange')?.setValue(event);
    } else {
      this.widgetDataForm().get('dateRange')?.setValue(null);
      delete this.widget.dateRange;
    }
  }

  addDefaultSortBy(): void {
    if (
      this.widgetDataForm().get('sortField') &&
      this.widgetDataForm().get('sortDirection')
    ) {
      if (
        this.sortFields?.length > 0 &&
        !this.widgetDataForm().get('sortField')?.value
      ) {
        // Add default sort if value does not exist.
        const field = this.sortFields[0];
        this.widgetDataForm()
          .get('sortField')
          ?.setValue(
            field?.aggregation ? `${field.id}_${field.aggregation}` : field.id
          );
        this.widgetDataForm().get('sortDirection')?.setValue('desc');
      } else if (this.widgetDataForm().get('sortField')?.value) {
        // Check if the field exists or remove.
        const currentSort = this.widgetDataForm().get('sortField')?.value;
        let exists = false;
        for (const field of this.sortFields) {
          field?.aggregation
            ? (exists = `${field.id}_${field.aggregation}` === currentSort)
            : (exists = field.id === currentSort);
          if (exists) {
            break;
          }
        }
        !exists && this.widgetDataForm().get('sortField')?.setValue('');
      }
    }
  }

  setWidgetFieldsData(): void {
    const widgetDataset = this.datasets?.find(
      (dataset) => dataset._id === this.widget.dataset
    );
    if (!widgetDataset) {
      return;
    }

    const [scopes, fields] = this.scopeService.setWidgetFields(widgetDataset);
    this.scopes = scopes;
    this.fields = fields;
    this.sendFilters.emit(this.fields);
    this.setWidgetFilteredFields();
    this.datasetFilterCtrl.setValue(widgetDataset, {emitEvent: false});
    this.addFullSortFields();

    if (this.widget.widgetType === 'geoHeatMap') {
      this.locationDimension = this.fields.filter(
        (field: ScopeField) => field.dataType === 'location'
      );
    }
  }

  changeFieldsOnSelection(): void {
    const [fields, scope] = this.scopeService.onFieldSelectionChanged(
      this.widget,
      this.fields
    );
    this.fields = fields;
    this.widget.scope = scope;
    this.sendFilters.emit(this.fields);
  }

  setWidgetFilteredFields(search = ''): void {
    const widgetConfiguration: WidgetConfiguration = {
      widget: this.widget,
      fields: this.fields,
      locationDimension: this.locationDimension,
      allowDuplicate: false,
      search,
      from: 'widget',
    };

    const [filteredDimensions, filteredMetrics] =
      this.scopeService.filterMetricsAndDimensionsByFields(widgetConfiguration);

    this.filteredDimensions.next(filteredDimensions);
    this.filteredMetrics.next(filteredMetrics);
    if (this.widget.widgetType === 'scorecard') {
      this.filteredFields.set(
        [...filteredDimensions, ...filteredMetrics].sort((a, b) =>
          a.name.localeCompare(b.name)
        )
      );
    }
  }

  confirmationDatasetDialog(event: MatAutocompleteSelectedEvent): void {
    const dataset = event.option.value as Dataset;
    if (this.datasets !== undefined) {
      if (
        (this.selectedDataset &&
          this.widget.dimensions &&
          this.widget.dimensions?.length > 0) ||
        (this.widget.metrics && this.widget.metrics.length > 0)
      ) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            title: 'Are you sure you want to continue?',
            content:
              'Changing the DataSet will result in the removal of all the added data.',
          },
          width: '45wv',
        });

        const dialogSub = this.router.events.subscribe(() => {
          dialogRef.close();
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.selectedDataset = dataset; //this.datasets.find((data: Dataset) => data._id === dataset._id) || {};
            this.datasetFilterCtrl.setValue(this.selectedDataset);
            this.widget.dataset = this.selectedDataset._id;
            this.scopes = this.selectedDataset.scopes
              ? this.selectedDataset.scopes
              : [];
            this.widget.dimensions = [];
            this.widget.metrics = [];
            this.widget.filters = [];
            this.scorecardField.set(null);
            this.handleSelectedDataset();
            this.blockOrAllowMoreFields();
            this.resetDynamicTextValues();
          }
          if (!result) {
            event.option.deselect();
            const newSelect = this.datasetList.options
              .toArray()
              .find((o) => o.value._id === this.selectedDataset._id);
            newSelect?.select();
          }
          dialogSub.unsubscribe();
        });
      } else {
        this.selectedDataset = this.datasets.filter(
          (data: Dataset) => data._id === dataset._id
        )[0];
        this.widget.dataset = this.selectedDataset._id;
        this.scopes = this.selectedDataset.scopes
          ? this.selectedDataset.scopes
          : [];
      }
      this.handleSelectedDataset();
    }
  }

  private handleSelectedDataset(): void {
    if (this.selectedDataset !== undefined) {
      if (this.widget.widgetType !== 'geoHeatMap') {
        this.filteredScopes.next(this.selectedDataset.scopes!.slice());
      }
      if (this.widget.widgetType === 'geoHeatMap') {
        this.setLocationScope();
      }

      this.newMeasureForm.get('fieldId')?.enable();
      this.newDimensionForm.get('fieldId')?.enable();
      this.setWidgetFieldsData();

      if (this.widget.widgetType === 'gallery') {
        // Add Default Metric & Dimension
        if (this.fields?.some(this.checkAdPreview)) {
          this.fields.forEach((field: ScopeField) => {
            if (field?.id === 'adPreview') {
              this.widget.dimensions = [field];
              this.changeFieldsOnSelection();
            }

            this.allowMultipleFields = false;
            this.blockOrAllowMoreFields();
          });
        } else if (this.fields?.some(this.checkPostPreview)) {
          this.fields.forEach((field: ScopeField) => {
            if (field?.id === 'postPreview') {
              this.widget.dimensions = [field];
              this.changeFieldsOnSelection();
            }

            this.allowMultipleFields = false;
            this.blockOrAllowMoreFields();
          });
        } else {
          this.widget.dimensions = [];
          this.widget.metrics = [];
        }
      }
    }

    if (this.widget.widgetType === 'dynamictext') {
      this.ckEditorWsmActions();
    }
    this.widgetDataForm().markAsPristine();
  }

  onCKEditorReady(editor: ClassicEditor): void {
    this.editorInstance = editor;
    this.ckEditorWsmActions();
    if (this.editorInstance) {
      this.editorInstance.ui.view.stickyPanel['checkIfShouldBeSticky']();
    }
  }

  resetDynamicTextValues(): void {
    if (this.widget.widgetType === 'dynamictext') {
      const newText = this.translate.instant(
        'reporting.widget_editor.data_form.rich_advance_message'
      );
      this.widgetService.setChangeDynamicTextValue({
        isInitialValue: false,
        from: 'editor',
        widgetId: this.widget.id,
        newText: {innerHtml: newText, originalHtml: newText},
      });

      if (
        this.ckEditorCtrl.value === 'advanced' &&
        this.widget.dynamicConditionalData
      ) {
        this.widget.dynamicConditionalData.fields = [];
      }
      this.widgetDataForm().get('richText')?.reset();
      this.widgetDataForm().get('richText')?.markAsDirty();
    }
  }

  ckEditorWsmActions(): void {
    if (this.widget.widgetType === 'dynamictext' && this.editorInstance) {
      (
        this.editorInstance.ui.view.toolbar.items.get(12) as ButtonView
      ).isEnabled = Boolean(
        this.widget?.dateRange || this.reportPage?.dateRange
      );
      (
        this.editorInstance.ui.view.toolbar.items.get(13) as ButtonView
      ).isEnabled = Boolean(this.widget.dataset);
    }
  }

  openDynamicMetric(): void {
    const dialogRef = this.dialog.open(DynamicMetricsComponent, {
      data: {
        widget: this.widget,
        reportPage: this.reportPage,
        fields: this.fields,
        metricTypes: this.metricTypes,
      },
      width: '400px',
      minHeight: '300px',
    });

    const dialogSub = this.router.events.subscribe(() => {
      dialogRef.close();
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res) {
          const newEvent = new CustomEvent('onClickWsmBtnData', {detail: res});
          window.dispatchEvent(newEvent);
        } else {
          const newEvent = new CustomEvent('onClickWsmBtnData', {
            detail: {value: null},
          });
          window.dispatchEvent(newEvent);
        }

        dialogSub.unsubscribe();
      });
  }

  openDateRangeDialog(): void {
    const dialogRef = this.dialog.open(DynamicDateRangeTextComponent, {
      data: {widget: this.widget, reportPage: this.reportPage},
      width: '400px',
      minHeight: '320px',
    });

    const dialogSub = this.router.events.subscribe(() => {
      dialogRef.close();
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res) {
          const val = {id: `${Date.now()}`, value: 'date_' + res.event};
          const newEvent = new CustomEvent('onClickWsmDateRangeBtnData', {
            detail: val,
          });
          window.dispatchEvent(newEvent);
        } else {
          const newEvent = new CustomEvent('onClickWsmDateRangeBtnData', {
            detail: {value: null},
          });
          window.dispatchEvent(newEvent);
        }
        dialogSub.unsubscribe();
      });
  }

  validateMetricsInsideTheEditor(value: string): void {
    const regexBraces = /{{([A-Za-z][A-Za-z0-9]+)_?([A-Za-z][_A-Za-z0-9]+)}}/g;
    const matches = value ? value?.match(regexBraces) : null;

    if (!matches) {
      return;
    }

    const metrics: ScopeField[] = this.fields.filter((field) =>
      this.metricTypes.includes(field.dataType!)
    );
    this.widget.metrics = this.scopeService.updateWidgetMetricsFromMatches(
      metrics,
      matches
    );
  }
}
