import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {CustomError, ReportTemplate} from 'src/app/shared/models';
import {
  AuthenticationService,
  LoadingService,
  MobileService,
  NotificationService,
  ReportService,
} from '../../../../core/services';
import {NewReportWizardComponent} from '../new-report-wizard/new-report-wizard.component';
import {NgClass} from '@angular/common';
import {TemplateGalleryCarouselComponent} from '../template-gallery-carousel/template-gallery-carousel.component';
import {CtaContainerComponent} from '../../../../shared/components/cta-container/cta-container.component';
import {ReportingListComponent} from '../reporting-list/reporting-list.component';
import {TranslateModule} from '@ngx-translate/core';
import {HelperService} from '../../../../shared/helpers';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./reporting.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    TemplateGalleryCarouselComponent,
    CtaContainerComponent,
    ReportingListComponent,
    TranslateModule,
  ],
})
export class ReportingComponent implements OnInit {
  // services
  public loader = inject(LoadingService);
  private reportService = inject(ReportService);
  public authService = inject(AuthenticationService);
  private dialog = inject(MatDialog);
  private router = inject(Router);
  private helperService = inject(HelperService);
  private mobileService = inject(MobileService);
  private notificationService = inject(NotificationService); //Properties

  public templates = signal<ReportTemplate[]>([]);
  public countTemplates = signal<number>(0);
  public subs = new Subscription();

  //State Variables
  public isIndividual = signal<boolean>(false);
  public isMobile = signal<boolean>(false);
  public templateLoading = signal<boolean>(false);
  public isExternalUser = signal<boolean>(false);

  constructor() {
    this.mobileService.getMobileBehavior().subscribe((res) => {
      this.isMobile.set(res);
    });
  }

  ngOnInit(): void {
    this.helperService.updateAppTitle('', true);
    this.isExternalUser.set(this.authService.checkRole(['ROLE_EXTERNAL']));
    if (!this.isExternalUser() && !this.isMobile()) {
      this.getTemplates();
    }
  }

  getTemplates(): void {
    this.templateLoading.set(true);
    const params = {
      limit: 12,
      page: 1,
      search: [
        {
          fieldName: 'name',
          operator: 'contains',
          value: '',
        },
        {
          fieldName: 'connections',
          operator: 'in',
          value: [],
        },
        {
          fieldName: 'labels',
          operator: 'in',
          value: [],
        },
        {
          fieldName: 'orientation',
          operator: 'in',
          value: [],
        },
      ],
      sortBy: [
        {
          'by': 'favorite',
          'order': 'desc',
        },
        {
          'by': 'lastModifiedDate',
          'order': 'desc',
        },
      ],
    };
    this.reportService.getAllTemplates(params).subscribe({
      next: (res) => {
        this.templates.set(res.data);
        this.countTemplates.set(res.total);
        this.templateLoading.set(false);
      },
      error: (err: CustomError) => {
        this.notificationService.error(err ? err.message : 'Error', 5000);
        this.countTemplates.set(0);
        this.templateLoading.set(false);
      },
    });
  }

  openNewReportModal(): void {
    const dialogRef = this.dialog.open(NewReportWizardComponent, {
      data: {
        isIndividual: this.isIndividual,
      },
      panelClass: 'new-wizard-dialog',
      autoFocus: false,
      width: this.isIndividual() ? '40vw' : '65vw',
      maxHeight: '100vh',
      disableClose: false,
      hasBackdrop: true,
    });

    const dialogSub = this.router.events.subscribe(() => {
      dialogRef.close();
    });

    this.subs.add(
      dialogRef.afterClosed().subscribe(() => {
        dialogSub.unsubscribe();
      })
    );
  }

  onRefreshCarousel(event): void {
    this.getTemplates();
  }

  goToLibrary(): void {
    this.router.navigate(['/templates']).then();
  }
}
