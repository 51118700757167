import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import moment from 'moment';
import {MatMenuTrigger, MatMenu, MatMenuContent} from '@angular/material/menu';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';

import {NgClass, DatePipe} from '@angular/common';
import {DatePickerMenuComponent} from '../date-picker-menu/date-picker-menu.component';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {
  DateRange,
  ReportDateRange,
} from 'src/app/shared/models/report/report.model';
import {ReportService} from 'src/app/core/services/report.service';
import {DialogService} from 'src/app/core/services/dialog.service';
import {DatePickerDialogComponent} from 'src/app/shared/mobile/dialog/date-picker-dialog/date-picker-dialog.component';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    MatMenu,
    MatMenuContent,
    DatePickerMenuComponent,
    MatMenuTrigger,
    DatePipe,
    TranslateModule,
  ],
})
export class DatePickerComponent implements OnInit, OnChanges {
  private translate = inject(TranslateService);
  @ViewChild('dpMenuTrigger') trigger: MatMenuTrigger;

  // Inputs / Outputs
  @Input() isEditMode = true;
  @Input() dateRange;
  @Input() origin: string;
  @Input() isDisabled: boolean;
  @Input() isMobile: boolean;
  @Input() largeMobile: boolean;
  @Input() useGrayBg = false;
  @Input() disableCompare = false;
  @Output() rangeSelection = new EventEmitter<ReportDateRange>();

  // State Variables
  hasChanges: boolean;

  // Properties
  dateRangeValue: {[k: string]: any} = {};
  relativeRange: {dateCount: number; relativeTo: string; monthRange?: string};
  currentOption: any = 'CUSTOM_DATE';
  compareOption: any = 'PREVIOUS_PERIOD';
  selectedRange: DateRange = {start: undefined, end: undefined};
  selectedComparisonRange: DateRange = {start: undefined, end: undefined};
  datePayload: ReportDateRange;
  locale = signal<string>(this.translate.currentLang);

  constructor(
    public reportService: ReportService,
    private dialog: MatDialog,
    private router: Router,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.updateDatePicker();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dateRange?.currentValue?.length === 0) {
      this.dateRangeValue = {};
    } else {
      this.updateDatePicker();
    }
  }

  updateDatePicker(): void {
    let currentStart = new Date();
    let currentEnd = new Date();
    let previousStart = new Date();
    let previousEnd = new Date();

    this.datePayload = {current: {}, previous: {}};

    if (this.dateRange !== undefined && this.dateRange !== null) {
      if (this.dateRange.current !== undefined) {
        if (
          this.dateRange.current.start !== null &&
          this.dateRange.current.start !== undefined
        ) {
          if (
            this.dateRange.current.start instanceof moment &&
            this.dateRange.current.end instanceof moment
          ) {
            const current = {
              start: this.dateRange.current.start.toString(),
              end: this.dateRange.current.end.toString(),
            };
            this.dateRange = {...this.dateRange, current};
          }

          currentStart = new Date(
            this.dateRange.current.start +
              (this.dateRange.current.start.includes('T') ? '' : 'T00:00')
          );
          currentEnd = new Date(
            this.dateRange.current.end +
              (this.dateRange.current.end.includes('T') ? '' : 'T00:00')
          );
        }

        if (this.dateRange.current.relative) {
          if (this.dateRange.current.relative === 'RELATIVE_RANGE') {
            this.relativeRange = {
              dateCount: this.dateRange.current.dateCount,
              relativeTo: this.dateRange.current.relativeTo,
            };

            if (this.dateRange.current.relativeTo === 'months') {
              this.relativeRange.monthRange = this.dateRange.current.monthRange;
            }

            if (this.datePayload.current?.dateCount) {
              this.datePayload.current.dateCount =
                this.dateRange.current.dateCount;
            }
            if (this.datePayload.current?.relativeTo) {
              this.datePayload.current.relativeTo =
                this.dateRange.current.relativeTo;
            }
            if (this.datePayload.current?.monthRange) {
              this.datePayload.current.monthRange =
                this.dateRange.current.monthRange;
            }
          }

          if (this.isEditMode) {
            if (!this.dateRangeValue?.range?.dates) {
              this.setRelativeDate(currentStart, currentEnd, 'range');
            }
          } else {
            this.setRelativeDate(currentStart, currentEnd, 'range');
          }
        }
      }

      this.datePayload.current = {
        start: this.dateRange.current?.start,
        end: this.dateRange.current?.end,
        relative: this.dateRange.current?.relative || null,
      };

      if (this.dateRange?.previous && this.dateRange?.isCompare) {
        if (this.dateRange.previous.start) {
          if (
            this.dateRange.previous.start instanceof moment &&
            this.dateRange.previous.end instanceof moment
          ) {
            const pStart = this.dateRange.previous.start.toString();
            const pEnd = this.dateRange.previous.end.toString();
            this.dateRange = {
              ...this.dateRange,
              previous: {
                start: pStart,
                end: pEnd,
              },
            };
          }

          previousStart = new Date(
            this.dateRange.previous.start +
              (this.dateRange?.previous?.start?.includes('T') ? '' : 'T00:00')
          );
          previousEnd = new Date(
            this.dateRange.previous.end +
              (this.dateRange?.previous?.end?.includes('T') ? '' : 'T00:00')
          );
        }

        if (this.isEditMode) {
          if (!this.dateRangeValue.compare?.dates) {
            this.setRelativeDate(previousStart, previousEnd, 'compare');
          }
        } else {
          this.setRelativeDate(previousStart, previousEnd, 'compare');
        }

        this.datePayload.previous = {
          start: this.dateRange.previous.start,
          end: this.dateRange.previous.end,
          relative: this.dateRange.previous.relative || null,
        };

        this.compareOption = this.dateRange.previous?.relative;
        this.currentOption = this.dateRangeValue?.range?.relative;

        this.selectedComparisonRange = {
          start: this.dateRangeValue.compare?.dates.start,
          end: this.dateRangeValue.compare?.dates.end,
        };
      } else {
        this.dateRangeValue.compare = {
          dates: {
            start: null,
            end: null,
          },
        };

        this.currentOption = this.dateRangeValue?.range?.relative;

        this.selectedComparisonRange = {
          start: null,
          end: null,
        };
      }
    }

    if (
      this.origin === 'widget' &&
      this.dateRangeValue?.range?.dates?.start &&
      (this.currentOption === 'CUSTOM_DATE' ||
        this.currentOption === 'RELATIVE_RANGE')
    ) {
      this.selectedRange = {
        start: moment(this.dateRangeValue?.range?.dates?.start),
        end: moment(this.dateRangeValue?.range?.dates?.end),
      };
    } else {
      this.selectedRange = {
        start: this.dateRangeValue?.range?.dates?.start,
        end: this.dateRangeValue?.range?.dates?.end,
      };
    }
  }

  onDateSelection(event): void {
    this.dateRangeValue = event;
    if (this.dateRangeValue) {
      const dateRangeRelative = this.dateRangeValue.range.relative;
      const dateObject: ReportDateRange = {
        current: null,
      };

      if (this.origin === 'page') {
        dateObject.applyToAll = event?.applyToAll;
      }

      dateObject.isCompare = event?.isCompare;

      if (dateRangeRelative) {
        if (
          dateRangeRelative === 'CUSTOM_DATE' ||
          dateRangeRelative === 'RELATIVE_RANGE'
        ) {
          dateObject.current = this.dateRangeValue?.range?.dates || {};

          dateObject.current = {
            ...dateObject.current,
            relative: dateRangeRelative,
          };

          if (dateRangeRelative === 'RELATIVE_RANGE') {
            dateObject.current.relativeTo =
              this.dateRangeValue?.range?.relativeTo;
            dateObject.current.monthRange =
              this.dateRangeValue?.range?.monthRange;
            if (this.dateRangeValue?.range?.dateCount) {
              dateObject.current.dateCount =
                this.dateRangeValue?.range?.dateCount;
            }
          }
        } else {
          dateObject.current = {
            relative: this.dateRangeValue?.range.relative,
          };
        }
      }

      if (
        this.dateRangeValue?.compare?.dates?.start &&
        this.dateRangeValue?.compare?.dates?.end
      ) {
        if (this.dateRangeValue?.compare?.relative === 'CUSTOM_DATE') {
          dateObject.previous = this.dateRangeValue?.compare?.dates || {};
          if (this.dateRangeValue.compare.relative) {
            dateObject.previous = {
              ...dateObject.previous,
              relative: this.dateRangeValue?.compare?.relative,
            };
          }
        } else {
          dateObject.previous = {
            relative: this.dateRangeValue?.compare?.relative,
          };
        }
      }
      this.datePayload = {...dateObject};
      this.rangeSelection.emit(this.datePayload);
    }

    if (!this.dateRangeValue) {
      this.rangeSelection.emit(undefined);
    }

    this.trigger.closeMenu();
  }

  setRelativeDate(currentStart, currentEnd, value): void {
    if (this.dateRangeValue) {
      this.dateRangeValue[value] = {
        relative: this.dateRange.current.relative,
        dates: {
          start: moment(currentStart),
          end: moment(currentEnd),
        },
      };
    }
  }

  openMobileDatePicker(): void {
    const onCloseActions = (res): void => {
      if (res) {
        this.onDateSelection(res);
      }
    };
    this.dialogService.openDialog(
      DatePickerDialogComponent,
      {
        data: {
          showClear: origin === 'widget',
          currentOption: this.currentOption,
          compareOption: this.compareOption,
          selectedComparisonRange: this.selectedComparisonRange,
          selectedRange: this.selectedRange,
          relativeRange: this.relativeRange,
          origin: this.origin,
          isMobile: this.isMobile,
        },
      },
      onCloseActions.bind(this)
    );
  }
}
